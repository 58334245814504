import * as d3 from "d3";
import dayjs from "dayjs";
import { MutableRefObject, useRef } from "react";
import { Entity } from "../../../models/viz/Entity";
import { MediaSource } from "../../../models/media/MediaSource";
import { distributeRowData } from "../../../utilities/RowDataUtils";
import { DateFormats } from "../../../utilities/dates";
import { FragmentsMap } from "../../playback/components/useLoadFragments";
import { TimelineEntityVm } from "./TimelineEntityVm";

export const rowCount = 5;
export const topLabelHeight = 30;
export const spriteHeight = 12;
export const interSpriteSpacing = 8;
export const rowHeight = spriteHeight + interSpriteSpacing;
export const spriteFieldHeight = rowCount * rowHeight + interSpriteSpacing;
export const totalHeight = topLabelHeight + spriteFieldHeight + rowHeight;

export type TimelineLabel = { type: "clip" | "tag"; title: string; startsAt: number; endsAt: number };

function useTimeline(
    fragmentsMap: MutableRefObject<FragmentsMap>,
    isShowingDebugUi: boolean,
    media: MediaSource,
    timelineRef: MutableRefObject<SVGSVGElement | undefined>,
    totalWidth: MutableRefObject<number>,
    labels: MutableRefObject<TimelineLabel[]>,
) {
    const domainOffset = useRef(0);
    const secondsPerPixel = useRef(1.6); // Zoom
    const xScale = useRef<d3.ScaleTime<number, number, never> | undefined>(undefined);
    const yScale = useRef<d3.ScaleLinear<number, number, never> | undefined>(undefined);
    const entities = useRef<TimelineEntityVm[]>(undefined);

    function setTimelineAttributes() {
        d3.select(timelineRef.current)
            .attr("class", "timeline-d3-chart")
            .attr("width", totalWidth.current)
            .attr("height", totalHeight);
    }

    function drawTimeline(centrePointOverride?: number) {
        xScale.current = d3
            .scaleTime()
            .domain(calculateDomain(centrePointOverride))
            .range([1, totalWidth.current - 1])
            .clamp(true);
        yScale.current = d3.scaleLinear().rangeRound([0, rowHeight]);

        drawLabels();
        drawSprites(entities.current);
        drawBackgroundGrid();
    }

    function drawSprites(entities: TimelineEntityVm[]) {
        const timeline = d3.select(timelineRef.current);
        const spriteTransform = (d: TimelineEntityVm) => {
            const x = xScale.current(d.startsAt);
            const y = yScale.current(d.dataRow) + interSpriteSpacing + topLabelHeight;
            return `translate(${x}, ${y})`;
        };

        timeline.selectAll(".sprite").remove();

        timeline
            .selectAll("chart")
            .data(entities)
            .enter()
            .insert("rect", ":first-child")
            .attr("class", "sprite")
            .attr("rx", 6)
            .attr("ry", 6)
            // Conditionally add class to empty fragment groups (if debug mode is on)
            .classed(
                "sprite-debug",
                (entity, _, __) => isShowingDebugUi && fragmentsMap.current[entity.sourceObject.id]?.length == 0,
            )
            .attr("transform", spriteTransform)
            .attr("height", spriteHeight)
            .attr("width", (d: TimelineEntityVm) => Math.max(12, xScale.current(d.endsAt) - xScale.current(d.startsAt)))
            .attr("fragmentGroupId", (d) => d.sourceObject.id);
    }

    function drawLabels() {
        const timeline = d3.select(timelineRef.current);

        const labelTransform = (d: { startsAt: number }) => {
            return `translate(${xScale.current(d.startsAt)}, 6)`;
        };

        const labelWidth = (d: { startsAt: number; endsAt: number }) => {
            return Math.max(22, xScale.current(d.endsAt) - xScale.current(d.startsAt));
        };

        timeline.selectAll(".label").remove();

        const labelGroups = timeline
            .selectAll("chart")
            .data(labels.current)
            .enter()
            .insert("g", ":first-child")
            .attr("class", "label")
            .attr("transform", labelTransform);

        labelGroups.append("title").text((d) => d.title);

        labelGroups
            .append("rect")
            .attr("class", (d) => `label-background ${d.type}-label`)
            .attr("width", labelWidth)
            .attr("height", spriteHeight);

        const iconGroup = labelGroups.append("g");

        const icon = iconGroup
            .append("path")
            .attr("class", "label-icon")
            .attr("transform", (d) => "scale(0.03)")
            .attr("d", (d) => getIcon(d));

        iconGroup.attr(
            "transform",
            (d) => `translate(${(labelWidth(d) - icon.node().getBoundingClientRect().width) / 2}, 1)`,
        );
    }

    function getIcon(label: TimelineLabel) {
        switch (label.type) {
            case "tag":
                // FontAwesome icon tag solid
                return "M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z";
            case "clip":
                // FontAwesome icon clapperboard solid
                return "M448 32l-86.1 0-1 1-127 127 92.1 0 1-1L453.8 32.3c-1.9-.2-3.8-.3-5.8-.3zm64 128l0-64c0-15.1-5.3-29.1-14-40l-104 104L512 160zM294.1 32l-92.1 0-1 1L73.9 160l92.1 0 1-1 127-127zM64 32C28.7 32 0 60.7 0 96l0 64 6.1 0 1-1 127-127L64 32zM512 192L0 192 0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-224z";
        }
    }

    function drawBackgroundGrid() {
        const timeline = d3.select(timelineRef.current);
        timeline.select("g.grid").remove();

        const xAxis = d3.axisBottom<Date>(xScale.current).tickFormat(formatTickLabels);
        const xAxisGenerator = timeline
            .insert("g", ":first-child")
            .attr("class", "grid")
            .attr("transform", `translate(0, ${totalHeight - rowHeight})`)
            .call(xAxis);

        xAxisGenerator.selectAll(".tick line").attr("y1", 25).attr("y2", -spriteFieldHeight).attr("stroke", "#aaa");
        xAxisGenerator.selectAll(".tick text").attr("y", 8).attr("dx", 2).attr("text-anchor", "start");

        timeline.select(".domain").attr("stroke", "#aaa");

        // Background representing area of timeline with video
        const startsAtPoint = xScale.current(media.startsAt);
        const endsAtPoint = xScale.current(media.startsAt + media.duration);
        timeline
            .select("g.grid")
            .insert("rect", ":first-child")
            .attr("class", "grid-bg")
            .attr("stroke", "rgba(255, 255, 255, 0.2)")
            .attr("rx", 10)
            .attr("ry", 10)
            .attr("width", endsAtPoint - startsAtPoint)
            .attr("x", startsAtPoint)
            .attr("y", 0)
            .attr("height", totalHeight)
            .attr("transform", `translate(0, -${totalHeight - rowHeight})`);
    }

    function formatTickLabels(value: Date): string {
        if (secondsPerPixel.current < 1) {
            return dayjs(value).format(DateFormats.timeSeconds);
        } else if (secondsPerPixel.current < 500) {
            return dayjs(value).format(DateFormats.time);
        } else if (secondsPerPixel.current < 700) {
            return dayjs(value).format(DateFormats.dayMonthTime);
        } else {
            return dayjs(value).format(DateFormats.dayMonthYear);
        }
    }

    function calculateCentreTime(startTime: number, endTime: number, centrePoint: number) {
        return Math.round(startTime + (endTime - startTime) * centrePoint);
    }

    function calculateDomainStart(centreTime: number, centrePoint: number) {
        return Math.round(
            domainOffset.current + centreTime - totalWidth.current * secondsPerPixel.current * 1000 * centrePoint,
        );
    }

    function calculateDomainEnd(centreTime: number, centrePoint: number) {
        return (
            domainOffset.current + centreTime + totalWidth.current * secondsPerPixel.current * 1000 * (1 - centrePoint)
        );
    }

    function calculateDomain(centrePointOverride?: number): [number, number] {
        const centrePoint = centrePointOverride ?? 0.5;

        let startTime = media.startsAt;
        let endTime = startTime + media.duration;

        // Different if user has zoomed or panned
        if (xScale.current) {
            const currentDomain = xScale.current.domain();
            startTime = currentDomain[0].getTime();
            endTime = currentDomain[1].getTime();
        }

        const centreTime = calculateCentreTime(startTime, endTime, centrePoint);

        const domainStartsAt = calculateDomainStart(centreTime, centrePoint);
        const domainEndsAt = calculateDomainEnd(centreTime, centrePoint);

        return [domainStartsAt, domainEndsAt];
    }

    function updateEntities(nextEntities: Entity[]) {
        const data = distributeRowData(
            nextEntities.map((e) => new TimelineEntityVm(e)),
            rowCount,
        );

        for (let i = 0; i < data.length; i++) {
            const entity = data[i];
            entity.dataRow = rowCount - entity.dataRow - 1;
        }

        entities.current = data;
    }

    return {
        domainOffset,
        secondsPerPixel,
        xScale,
        entities,
        drawTimeline,
        setTimelineAttributes,
        calculateDomainStart,
        calculateDomainEnd,
        calculateCentreTime,
        updateEntities,
    };
}

export default useTimeline;
