import { capitaliseFirst } from "../../../utilities/helpers";
import { TagObjectEntityAdapter } from "../entityAdapters/TagObjectEntityAdapter";
import { DisplayStringEntityInformationGenerator } from "../entityInformation/DisplayStringEntityInformationGenerator";
import { OptionEntityFilter, StringPropertyOptionFilterPredicate } from "../filters/OptionEntityFilter";
import { StringEntityFilter, StringPropertyGetter } from "../filters/StringEntityFilter";
import { ExportEntitiesToCsv } from "../operations/ExportEntitiesToCsv";
import { RefreshDataOperation } from "../operations/RefreshDataOperation";
import { StringSorter } from "../sorters/StringSorter";
import { DataDescription } from "./DataDescription";
import { EntityProperties } from "./EntityProperties";
import { TagObjectTypes } from "cloud-core/tags/TagObject";

export class TagObjectDataDescription extends DataDescription {
    constructor() {
        super();

        this.infoGenerator = {
            titleGenerator: new DisplayStringEntityInformationGenerator(EntityProperties.Name.key, "Name"),
            subtitleGenerator: new DisplayStringEntityInformationGenerator(EntityProperties.ObjectCategory.key, "Type"),
        };

        const categoryValues = [...TagObjectTypes];

        this.setProperties([
            EntityProperties.Name,
            EntityProperties.ObjectCategory.withPossibleValues(categoryValues),
            EntityProperties.Comments,
            EntityProperties.Meta.CreatedAt,
            EntityProperties.Meta.UpdatedAt,
            EntityProperties.Meta.FreeFormSearch,
            EntityProperties.Aliases.withConditionalOn(PERSON),
            EntityProperties.Vehicle.RegistrationPlate.withConditionalOn(VEHICLE),
            EntityProperties.Vehicle.Make.withConditionalOn(VEHICLE),
            EntityProperties.Vehicle.Model.withConditionalOn(VEHICLE),
            EntityProperties.Vehicle.Colour.withConditionalOn(VEHICLE),
            EntityProperties.Vehicle.Type.withConditionalOn(VEHICLE),
            EntityProperties.Location.Address.withConditionalOn(LOCATION),
            EntityProperties.Location.PostCode.withConditionalOn(LOCATION),
            EntityProperties.Location.Latitude.withConditionalOn(LOCATION),
            EntityProperties.Location.Longitude.withConditionalOn(LOCATION),
            EntityProperties.Group.ApproxSize.withConditionalOn(GROUP),
            EntityProperties.Animal.Species.withConditionalOn(ANIMAL),
            EntityProperties.Animal.Breed.withConditionalOn(ANIMAL),
            EntityProperties.Animal.Colour.withConditionalOn(ANIMAL),
        ]);
        this.defaultSorter = StringSorter.fromProperty(EntityProperties.Name);
        this.sorters = [
            this.defaultSorter,
            StringSorter.fromProperty(EntityProperties.ObjectCategory),
            StringSorter.fromProperty(EntityProperties.Comments),
        ];
        this.operations = [new RefreshDataOperation(), new ExportEntitiesToCsv("Objects")];
        this.filters = {
            search: new StringEntityFilter("Search", StringPropertyGetter(EntityProperties.Meta.FreeFormSearch.key)),
            category: new OptionEntityFilter(
                "Category",
                StringPropertyOptionFilterPredicate(EntityProperties.ObjectCategory.key),
                categoryValues.map((v) => ({ value: v, label: capitaliseFirst(v) })),
            ),
        };

        this.entityAdapter = new TagObjectEntityAdapter(this);
    }
}

const PERSON = {
    propertyKey: EntityProperties.ObjectCategory.key,
    value: "person",
};

const GROUP = {
    propertyKey: EntityProperties.ObjectCategory.key,
    value: "group",
};

const VEHICLE = {
    propertyKey: EntityProperties.ObjectCategory.key,
    value: "vehicle",
};

const LOCATION = {
    propertyKey: EntityProperties.ObjectCategory.key,
    value: "location",
};

const ANIMAL = {
    propertyKey: EntityProperties.ObjectCategory.key,
    value: "animal",
};
