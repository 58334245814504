import dayjs from "dayjs";
import { useMemo } from "react";
import { Container } from "react-bootstrap";
import ConditionalPlaceholder from "../../../components/ConditionalPlaceholder";
import { useView } from "../../../hooks/useView";
import { PlayState } from "../../../store/analytics/Analytics";
import { DateFormats } from "../../../utilities/dates";
import useMediaSources from "../../../hooks/useMediaSources";

export interface CalculatedData {
    frameDatetime: string[];
    frameElapsedTime: string[];
    frameRate: number;
    playbackRate: string;
}

export const DEFAULTS: CalculatedData = {
    frameDatetime: ["0000-00-00 00:00:00", "000"],
    frameElapsedTime: ["00:00:00", "000"],
    frameRate: 0,
    playbackRate: "0.0",
};

export interface VideoPlayerHeaderProps {
    isMediaLoaded: boolean;
    viewId: string;
    mediaId: string;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
}

function VideoPlayerHeader(props: VideoPlayerHeaderProps) {
    const { view } = useView(props.viewId);
    const { activeMediaSource: media } = useMediaSources(props.viewId, props.mediaId);
    const video = media?.files?.initialDisplay;

    const { frameElapsedTime, frameDatetime, frameRate, playbackRate } = useMemo((): CalculatedData => {
        const calculatedData = { ...DEFAULTS };

        if (view?.cursor === undefined || media === undefined) {
            return calculatedData;
        }

        calculatedData.frameDatetime = dayjs(view.cursor)
            .format(DateFormats.yearMonthDayWithTimeMilliseconds)
            .split("#");

        calculatedData.frameElapsedTime = dayjs(view.cursor - media.startsAt)
            .utc()
            .format(DateFormats.timeMilliseconds)
            .split("#");

        if (view.mediaStates.play !== ("paused" as PlayState)) {
            const rate = props.videoRef.current.playbackRate;

            calculatedData.frameRate = Math.round(rate * video.frameRate);
            calculatedData.playbackRate = rate.toFixed(1);
        }

        return calculatedData;
    }, [view?.cursor, media]);

    function renderFpsLabel() {
        return <span className="fps-label ms-1">FPS</span>;
    }

    function renderSecondsFraction(secondsFraction: string) {
        return <span className="seconds-fraction">{secondsFraction}</span>;
    }

    const isLoading = !props.isMediaLoaded;

    return (
        <div className="video-header d-flex justify-content-center placeholder-glow text-body-emphasis">
            <Container className="p-0 d-flex flex-column justify-content-between">
                <ConditionalPlaceholder isLoading={isLoading} xs={5}>
                    <p className="mb-0 fs-5 font-monospace">
                        {frameDatetime[0]}.{renderSecondsFraction(frameDatetime[1])}
                    </p>
                </ConditionalPlaceholder>
                <ConditionalPlaceholder isLoading={isLoading} xs={4}>
                    <p className="mb-0 text-muted font-monospace">
                        {playbackRate}x playing at {frameRate}
                        {renderFpsLabel()}
                    </p>
                </ConditionalPlaceholder>
            </Container>
            <Container className="p-0 text-center d-flex flex-column justify-content-between align-items-center">
                <ConditionalPlaceholder isLoading={isLoading} xs={6}>
                    <p className="mb-0 video-name">{media?.name}</p>
                </ConditionalPlaceholder>
                <ConditionalPlaceholder isLoading={isLoading} xs={4}>
                    <p className="mb-0 text-muted">
                        {video?.width ?? 0}x{video?.height ?? 0}
                        <span className="ms-2">
                            {video?.frameRate ?? 0}
                            {renderFpsLabel()}
                        </span>
                    </p>
                </ConditionalPlaceholder>
            </Container>
            <Container className="p-0 text-end d-flex flex-column justify-content-between align-items-end">
                <ConditionalPlaceholder isLoading={isLoading} xs={5}>
                    <p className="mb-0 fs-5 font-monospace">
                        {frameElapsedTime[0]}.{renderSecondsFraction(frameElapsedTime[1])}
                    </p>
                </ConditionalPlaceholder>
                <ConditionalPlaceholder isLoading={isLoading} xs={4}>
                    <p className="mb-0 text-muted"></p>
                </ConditionalPlaceholder>
            </Container>
        </div>
    );
}

export default VideoPlayerHeader;
