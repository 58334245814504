import { Form } from "react-bootstrap";
import { AnalyticsMetadataGroup } from "../../../../models/analysis/Config";
import { ImportMetadataResults } from "./ImportMetadata";
import React, { useEffect, useState } from "react";

export interface ImportMetadataGroupProps {
    group: AnalyticsMetadataGroup;
    results: ImportMetadataResults;
    setResults: (update: React.SetStateAction<object>) => void;
    onValidityChanged: (isValid: boolean) => void;
    showValidationWarnings: boolean;
}

type ImportMetadataGroupState = {
    name: string;
    value: string;
    isRequired: boolean;
};

function ImportMetadataGroup(props: ImportMetadataGroupProps) {
    const defaultValues = props.group.entries.map((e) => {
        return {
            name: e.name,
            value: props.results[props.group.name][e.name],
            isRequired: e.isRequired,
        };
    });

    const [validities, setValidities] = useState<boolean[]>(props.group.entries.map((_) => true));
    const [values, setValues] = useState<ImportMetadataGroupState[]>(defaultValues);

    function handleValueChanged(newValue: string, i: number) {
        const newValues = [...values];
        newValues[i].value = newValue;
        setValues(newValues);
    }

    function updateValidity() {
        const newValidities = [...validities];
        let hasChanged = false;

        for (let i = 0; i < values.length; i++) {
            const entry = values[i];
            const isValid = entry.value !== "" || !entry.isRequired;
            newValidities[i] = isValid;

            if (isValid !== validities[i]) {
                hasChanged = true;
            }
        }

        if (hasChanged) {
            setValidities(newValidities);
        }
    }

    useEffect(() => {
        updateValidity();

        if (values === defaultValues) {
            return;
        }

        props.setResults((oldResults: ImportMetadataResults) => {
            const newResults = { ...oldResults };
            newResults[props.group.name] = {};

            values.forEach((value) => {
                newResults[props.group.name][value.name] = value.value;
            });

            return newResults;
        });
    }, [values]);

    useEffect(() => {
        const isValid = validities.reduce((acc, curr) => acc && curr, true);
        props.onValidityChanged(isValid);
    }, [validities]);

    return (
        <section className="gap-2 mb-3 group d-flex flex-column">
            <h5 className="mb-1">{props.group.name}</h5>
            {values.map((value, i) => {
                const isInvalid = props.showValidationWarnings && !validities[i];

                return (
                    <Form.Group key={i} onSubmit={(e) => e.preventDefault()}>
                        <Form.Label htmlFor={value.name}>
                            {value.name}
                            {value.isRequired && " *"}
                        </Form.Label>
                        <Form.Control
                            id={value.name}
                            name={value.name}
                            value={value.value}
                            onChange={(e) => handleValueChanged(e.currentTarget.value, i)}
                            isInvalid={isInvalid}
                            aria-invalid={isInvalid}
                        />
                    </Form.Group>
                );
            })}
        </section>
    );
}

export default ImportMetadataGroup;
