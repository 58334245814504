import { Button, Form, Modal } from "react-bootstrap";
import { useMemo, useState } from "react";
import {
    useCreateTagEventMutation,
    useGetAllTagObjectsQuery,
    useGetMediaSourceQuery,
    useUpdateTagEventMutation,
} from "../../../store/api/kinesense";
import SpinnerButton from "../../SpinnerButton";
import { TAG_EVENT_STANDARD_ACTIONS, TagEvent } from "../../../models/tags/TagEvent";
import { STANDARD_ACTION_PROTOTYPE_MAPPING, StandardTagEventAction, TagEventRelevance } from "cloud-core/tags/TagEvent";
import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import TagEventModalComments from "./components/TagEventModalComments";
import TagEventModalActions from "./components/TagEventModalActions";
import TagEventModalSubject from "./components/TagEventModalSubject";
import TagEventModalRelevance from "./components/TagEventModalRelevance";
import TagEventModalLocation from "./components/TagEventModalLocation";
import TagEventModalObject from "./components/TagEventModalObject";
import "./EditTagEventModal.scss";
import { VizContext } from "../../../models/viz/operations/DataOperation";
import NiceModal, { bootstrapDialog, useModal } from "@ebay/nice-modal-react";
import { tryExtractErrorMessage } from "../../../utilities/helpers";

export type TagObjectReactSelectOption = { value: string; label: string };

export interface EditTagEventModalProps {
    context: VizContext;
    tagEvent?: TagEvent;
}

const EditTagEventModal = NiceModal.create((props: EditTagEventModalProps) => {
    const modal = useModal();
    const { view, projectId } = props.context;

    const mediaId = view.data.hasAssociatedMedia ? view.data.primaryMediaId : undefined;
    const { data: media } = useGetMediaSourceQuery(
        { projectId, mediaId },
        { skip: projectId === undefined || mediaId === undefined },
    );

    const [requestCreateTagEvent, createTagEventResult] = useCreateTagEventMutation();
    const [requestUpdateTagEvent, updateTagEventResult] = useUpdateTagEventMutation();

    const { data: tagObjects, isFetching: isFetchingTagObjects } = useGetAllTagObjectsQuery(
        { projectId },
        { skip: projectId === undefined },
    );

    const shouldUpdate = props.tagEvent !== undefined;

    const initialValues = useMemo(() => {
        if (!shouldUpdate) {
            return undefined;
        }

        const showLocationChecked =
            props.tagEvent.locationIds.length > 0 &&
            STANDARD_ACTION_PROTOTYPE_MAPPING[props.tagEvent.action.type] != "subject-location";
        const isDurationChecked = props.tagEvent.duration !== 0;

        return {
            showLocationChecked,
            isDurationChecked,
        };
    }, []);

    const [selectedSubjects, setSelectedSubjects] = useState<TagObjectReactSelectOption[]>(
        (props.tagEvent?.subjects ?? []).map((s) => ({ value: s.objectId, label: s.name })),
    );
    const [selectedObjects, setSelectedObjects] = useState<TagObjectReactSelectOption[]>(
        (props.tagEvent?.objects ?? []).map((o) => ({ value: o.objectId, label: o.name })),
    );
    const [selectedLocations, setSelectedLocations] = useState<TagObjectReactSelectOption[]>(
        (props.tagEvent?.locations ?? []).map((l) => ({ value: l.objectId, label: l.name })),
    );
    const [selectedAction, setSelectedAction] = useState<StandardTagEventAction>(
        (props.tagEvent?.action as StandardTagEventAction) ?? {
            action: "entersScene",
            type: "standard",
        },
    );
    const [selectedRelevance, setSelectedRelevance] = useState<TagEventRelevance>(props.tagEvent?.relevance ?? "unset");
    const [comments, setComments] = useState(props.tagEvent?.comments ?? "");

    const prototypeMapping = TAG_EVENT_STANDARD_ACTIONS[selectedAction.action].prototype;
    const [isDurationChecked, setIsDurationChecked] = useState(initialValues?.isDurationChecked ?? false);
    const [showLocationCheck, setShowLocationCheck] = useState(initialValues?.showLocationChecked ?? false);
    const showLocationPrototype = prototypeMapping === "subject-location";
    const showLocation = showLocationCheck || showLocationPrototype;
    const showObject = prototypeMapping === "subject-object";

    const [startsAt, duration] = useMemo(() => {
        let startsAt = props.tagEvent?.startsAt ?? view?.cursor;
        if (startsAt === undefined) {
            return [media.startsAt, 0];
        }

        let duration = 0;
        if (initialValues?.isDurationChecked || view?.secondaryCursor !== undefined) {
            duration = props.tagEvent?.duration ?? Math.abs(startsAt - view.secondaryCursor);

            if (isDurationChecked && props.tagEvent === undefined) {
                startsAt = Math.min(view.cursor, view.secondaryCursor);
            }
        }

        return [startsAt, duration];
    }, [view?.cursor, view?.secondaryCursor, isDurationChecked]);

    function createTagEvent(newTagEvent: Partial<TagEvent>) {
        requestCreateTagEvent({ projectId, tagEvent: newTagEvent })
            .unwrap()
            .then(() => {
                Notifications.notify("Tag Event created", "A new Tag Event was created successfully");
                modal.hide();
            })
            .catch((e) => {
                Notifications.notify(
                    "Error creating tag event",
                    `The following error was encountered while attempting to create a new tag event: "${tryExtractErrorMessage(
                        e,
                    )}"`,
                );
            });
    }

    function updateTagEvent(newTagEvent: Partial<TagEvent>) {
        requestUpdateTagEvent({ projectId, tagEventId: props.tagEvent.eventId, tagEvent: newTagEvent })
            .unwrap()
            .then((tagEvent) => {
                Notifications.notify("Tag Event created", "A new Tag Event was created successfully");

                modal.resolve(tagEvent);
                modal.hide();
            })
            .catch((e) => {
                Notifications.notify(
                    "Error updating tag event",
                    `The following error was encountered while attempting to update an existing tag event: "${tryExtractErrorMessage(
                        e,
                    )}"`,
                );
            });
    }

    function handleOnSubmit(e: React.FormEvent) {
        e.preventDefault();

        const newTagEvent: Partial<TagEvent> = {
            action: selectedAction,
            subjectIds: selectedSubjects.map((s) => s.value),
            objectIds: showObject ? selectedObjects.map((o) => o.value) : [],
            locationIds: showLocation ? selectedLocations.map((l) => l.value) : [],
            relevance: selectedRelevance,
            occurrences: [{ mediaId, startOffset: startsAt - media.startsAt }],
            duration: isDurationChecked ? duration : 0,
            startsAt,
            comments,
        };

        if (shouldUpdate) {
            updateTagEvent(newTagEvent);
        } else {
            createTagEvent(newTagEvent);
        }
    }

    return (
        <Modal fullscreen="lg-down" {...bootstrapDialog(modal)} size="lg" centered backdrop="static" keyboard={false}>
            <Form className="edit-tag-event-modal-form" onSubmit={handleOnSubmit}>
                <Modal.Header closeButton>New Tag Event</Modal.Header>
                <Modal.Body
                    className={`gap-2${showLocation ? " show-location" : ""}${showObject ? " show-object" : ""}`}
                >
                    <section className="p-3 section-subject">
                        <h3 className="fs-5">Subject</h3>
                        <TagEventModalSubject
                            context={props.context}
                            tagObjects={tagObjects}
                            selectedSubjects={selectedSubjects}
                            setSelectedSubjects={setSelectedSubjects}
                            setSelectedObjects={setSelectedObjects}
                            isFetchingTagObjects={isFetchingTagObjects}
                        />
                    </section>

                    <section className="p-3 section-action">
                        <h3 className="fs-5">Action</h3>
                        <TagEventModalActions
                            selectedAction={selectedAction}
                            setSelectedAction={setSelectedAction}
                            showLocationPrototype={showLocationPrototype}
                            showLocationCheck={showLocationCheck}
                            setShowLocationCheck={setShowLocationCheck}
                        />
                    </section>

                    <section className="gap-3 p-3 section-comments d-flex flex-column">
                        <h3 className="mb-0 fs-5">Comments</h3>
                        <TagEventModalComments
                            comments={comments}
                            setComments={setComments}
                            selectedSubjects={selectedSubjects}
                            selectedObjects={selectedObjects}
                            selectedLocations={selectedLocations}
                            selectedAction={selectedAction}
                            showObject={showObject}
                            showLocation={showLocation}
                            showLocationCheck={showLocationCheck}
                        />
                    </section>

                    <section className="p-3 section-relevance">
                        <h3 className="fs-5">Relevance</h3>
                        <TagEventModalRelevance
                            selectedRelevance={selectedRelevance}
                            setSelectedRelevance={setSelectedRelevance}
                        />
                    </section>

                    <section className="p-3 section-location">
                        <h3 className="fs-5">Location</h3>
                        <TagEventModalLocation
                            context={props.context}
                            tagObjects={tagObjects}
                            isFetchingTagObjects={isFetchingTagObjects}
                            selectedLocations={selectedLocations}
                            setSelectedLocations={setSelectedLocations}
                            showLocation={showLocation}
                        />
                    </section>
                    <section className="p-3 section-object">
                        <h3 className="fs-5">Object</h3>
                        <TagEventModalObject
                            context={props.context}
                            tagObjects={tagObjects}
                            isFetchingTagObjects={isFetchingTagObjects}
                            selectedSubjects={selectedSubjects}
                            selectedObjects={selectedObjects}
                            setSelectedObjects={setSelectedObjects}
                            selectedAction={selectedAction}
                            showObject={showObject}
                        />
                    </section>
                </Modal.Body>
                <Modal.Footer className="p-1 justify-content-between d-flex align-items-center">
                    <div className="gap-3 d-flex">
                        <p className="m-0">
                            ...in video <strong>{media.name}</strong> at{" "}
                            <span className="text-decoration-underline">
                                {dayjs(startsAt).format(DateFormats.dayMonthYearWithTimeSeconds)}
                            </span>
                        </p>

                        {(view?.secondaryCursor !== undefined || props.tagEvent !== undefined) && duration > 0 && (
                            <Form.Label className="gap-1 m-0 d-flex">
                                <Form.Check
                                    checked={isDurationChecked}
                                    onChange={() => setIsDurationChecked(!isDurationChecked)}
                                ></Form.Check>
                                Duration <em>{dayjs.duration({ milliseconds: duration }).humanize()}</em>
                            </Form.Label>
                        )}
                    </div>
                    <div className="gap-2 d-flex">
                        <Button variant="secondary" onClick={modal.hide}>
                            Cancel
                        </Button>
                        <SpinnerButton
                            isLoading={createTagEventResult?.isLoading || updateTagEventResult?.isLoading}
                            label={shouldUpdate ? "Update" : "Create"}
                            buttonProps={{
                                type: "submit",
                                variant: "success",
                                style: { width: "5rem" },
                            }}
                        />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export default EditTagEventModal;
