import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ApplicationState } from "../store";
import { useGetProjectsQuery } from "../store/api/kinesense";
import * as GeneralStore from "../store/general/General";
import { Notifications } from "../utilities/Notifications/Notifications";
import { TaskArea } from "../utilities/Tasks/TaskArea";
import "./StatusBar.scss";

type ReactSelectOption = { value: string; label: string; mediaCount: number };

function StatusBar() {
    const dispatch = useDispatch();
    const { general } = useSelector((state: ApplicationState) => state);
    const activeProjectId = general?.activeProjectId;

    const { data: projects, isSuccess, isFetching } = useGetProjectsQuery();
    const hasLoadedProjects = isSuccess && !isFetching;
    const hasLoaded = hasLoadedProjects;

    function handleOnSelectedProject(opt: { value: string; label: string }) {
        dispatch(GeneralStore.actionCreators.setActiveProject(opt.value));
    }

    function handleOnClickNotifications() {
        if (Notifications.hasNotifications() || Notifications.isShowingOldNotifications()) {
            Notifications.clearNotifications();
        } else {
            Notifications.showOldNotifications();
        }
    }

    // const [activeNotifications, setActiveNotifications] = useState<Notification[]>([]);
    // const setNotifications = (notifications: Notification[]) => {
    //     setActiveNotifications([...notifications]);
    // };
    // useEffect(() => {
    //     Notifications.register("statusbar", setNotifications);
    // }, []);

    const options: ReactSelectOption[] = (projects ?? []).map((p) => ({
        value: p.projectId,
        label: p.name,
        mediaCount: p.mediaCount,
    }));
    const selectedOption = options.find((p) => p.value == activeProjectId);

    const formatLabel = (data: ReactSelectOption) => (
        <div>
            <div className="fw-bold">{data.label}</div>
            <div className="second-line fst-italic">{data.mediaCount} Videos</div>
        </div>
    );

    return (
        <footer className="status-bar">
            <div className="status-bar-project">
                <span className="fst-italic lh-sm">Active project:</span>
                {hasLoaded ? (
                    <Select
                        classNamePrefix="react-select"
                        onChange={handleOnSelectedProject}
                        menuPlacement="top"
                        options={options}
                        formatOptionLabel={formatLabel}
                        value={selectedOption}
                    />
                ) : (
                    <Spinner size="sm"></Spinner>
                )}
            </div>

            {/* <div className="status-bar-message">{activeNotifications[0]?.message}</div> */}

            <div className="status-bar-tray">
                <TaskArea />
                <button className="button-with-icon" onClick={handleOnClickNotifications}>
                    <FontAwesomeIcon icon={faBell} />
                </button>
            </div>
        </footer>
    );
}

export default StatusBar;
