import { Placeholder, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Task, getTaskProgress } from "../../models/tasks/Task";
import { motion } from "framer-motion";

interface TaskItemPRops {
    task: Task;
    mediaSourceName: string;
}

export function TaskItem(props: TaskItemPRops) {
    const isActive = !props.task.isComplete && !props.task.isFailed;
    const progress = getTaskProgress(props.task);

    let title: string;
    switch (props.task.type) {
        case "import":
            title = `${isActive ? "Importing" : "Imported"} media source "${props.mediaSourceName}"`;
            break;
        case "clip":
            title = `${isActive ? "Creating" : "Created"} a clip for media source "${props.mediaSourceName}"`;
            break;
    }

    return (
        <motion.div
            layout
            className={`py-2 px-3 position-relative border rounded w-100 d-flex align-items-center justify-content-between gap-3 shadow task${
                isActive ? " active-task" : ""
            }`}
        >
            <strong className="flex-grow-1 text-wrap">{title}</strong>
            <small className="gap-1 d-flex align-items-center">
                {props.task.isFailed ? (
                    <>
                        <FontAwesomeIcon className="text-danger" icon={faXmark} />
                        <span className="text-muted">Failed</span>
                    </>
                ) : props.task.isComplete ? (
                    <>
                        <FontAwesomeIcon className="text-success" icon={faCheck} />
                        <span className="text-muted">Done</span>
                    </>
                ) : (
                    <span className="text-muted text-nowrap">In progress - {progress}%</span>
                )}
            </small>
            <div className="progress-bar placeholder-glow">
                {props.task.isFailed ? (
                    <div className="bg-danger" role="progressbar" aria-label="complete" />
                ) : props.task.isComplete ? (
                    <div className="bg-success" role="progressbar" aria-label="complete" />
                ) : progress == 0 ? (
                    <Placeholder />
                ) : (
                    <ProgressBar now={progress} title={`${progress}%`} />
                )}
            </div>
        </motion.div>
    );
}
