import { ListGroup } from "react-bootstrap";
import ConditionalSpinner from "../../../components/ConditionalSpinner";
import { useGetProjectThumbnailsQuery, useGetTasksQuery } from "../../../store/api/kinesense";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import ReviewVideoListItem from "./ReviewVideoListItem";
import { SetStateAction, useEffect, useRef } from "react";
import useMediaSources from "../../../hooks/useMediaSources";

export interface ReviewVideoListProps {
    viewId: string;
    mediaId: string;
    setMediaId: (_: SetStateAction<string>) => void;
    isExpanded: boolean;
    setIsExpanded: (_: React.SetStateAction<boolean>) => void;
}

export function getReviewVideoListItemId(mediaId: string) {
    return `video-list-${mediaId}`;
}

function ReviewVideoList(props: ReviewVideoListProps) {
    const projectId = useSelector((state: ApplicationState) => state.general.activeProjectId);
    const videoSidebarRef = useRef<HTMLDivElement>(null);
    const listGroupRef = useRef<HTMLDivElement>(null);

    const { allMediaSources, hasLoadedMediaSources } = useMediaSources(props.viewId);
    const {
        data: thumbnails,
        isSuccess,
        isFetching,
        refetch,
    } = useGetProjectThumbnailsQuery({ projectId }, { skip: !projectId });
    const hasLoadedThumbnails = isSuccess && !isFetching;

    const { data: tasks } = useGetTasksQuery({ projectId }, { skip: !projectId });

    // Re-fetch thumbnails before the signed URLs expire
    useEffect(() => {
        if (!thumbnails?.expiresAt) {
            return;
        }

        const now = Date.now();
        const expiresIn = Math.max((thumbnails.expiresAt - now) / 2);
        if (expiresIn <= 0) {
            console.error(`Invalid thumbnail expiration time: ${expiresIn}ms`);
            return;
        }
        const handle = setTimeout(refetch, expiresIn);
        return () => {
            clearTimeout(handle);
        };
    }, [thumbnails?.expiresAt]);

    // Scroll selected media source into view if it is not fully contained within the scrollable view
    useEffect(() => {
        if (!props?.mediaId || !videoSidebarRef.current || !listGroupRef.current || !document?.getElementById) {
            return;
        }

        const top = listGroupRef.current.offsetTop - videoSidebarRef.current.getBoundingClientRect().top;
        const bottom = videoSidebarRef.current.offsetHeight + top;

        // NOTE: requires element ID for each video list item to be set correctly
        const el = document.getElementById(getReviewVideoListItemId(props.mediaId));
        if (!el?.scrollIntoView) {
            return;
        }

        const isInView = el.offsetTop >= top && el.offsetHeight + el.offsetTop <= bottom;
        if (!isInView) {
            el.scrollIntoView({ block: "center" });
        }
    }, [props.mediaId, videoSidebarRef.current?.offsetHeight]);

    return (
        <div className="position-relative video-sidebar" ref={videoSidebarRef}>
            <div className="review-video-list-inner-container">
                <ConditionalSpinner isLoading={!hasLoadedMediaSources}>
                    <ListGroup variant="flush" className="gap-2 d-flex flex-column" ref={listGroupRef}>
                        {allMediaSources?.map((data) => {
                            const thumbnail = thumbnails?.thumbnails?.[data.mediaId];
                            const task = (tasks ?? []).find(
                                (t) => t.references.mediaId == data.mediaId && t.type == "import",
                            );
                            return (
                                <ReviewVideoListItem
                                    key={data.mediaId}
                                    data={data}
                                    viewId={props.viewId}
                                    activeMediaId={props.mediaId}
                                    setActiveMediaId={props.setMediaId}
                                    thumbnail={thumbnail}
                                    hasLoadedThumbnails={hasLoadedThumbnails}
                                    task={task?.isFailed || task?.isComplete ? undefined : task}
                                />
                            );
                        })}
                    </ListGroup>
                </ConditionalSpinner>
            </div>
        </div>
    );
}

export default ReviewVideoList;
