import { Placeholder, PlaceholderProps } from "react-bootstrap";

export interface ConditionalPlaceholderProps extends PlaceholderProps {
    isLoading: boolean;
}

function ConditionalPlaceholder(props: ConditionalPlaceholderProps) {
    if (props.isLoading) {
        const placeholderProps: PlaceholderProps = { ...props };
        delete placeholderProps.children;
        delete placeholderProps.isLoading;

        return (
            <Placeholder
                {...placeholderProps}
                as={props.children.type}
                className={"placeholder " + (props.children.props.className ?? "")}
                role="status"
            />
        );
    }

    return props.children;
}

export default ConditionalPlaceholder;
