import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataOperation, VizContext } from "../../../../models/viz/operations/DataOperation";
import { useTheme } from "../../../../hooks/useTheme";

export interface VizListItemContextMenuProps {
    show: boolean;
    operations: DataOperation[];
    coords: { x: number; y: number };
    context: VizContext;
}

function VizListItemContextMenu(props: VizListItemContextMenuProps) {
    const { isDarkTheme } = useTheme();

    if (!props.show) {
        return null;
    }

    return (
        <ul
            className="p-0 rounded viz-list-item-context-menu d-flex align-items-start justify-content-center flex-column"
            style={{ left: props.coords.x + 15, top: props.coords.y + 10 }}
        >
            {props.operations.map((operation) => (
                <li className="rounded" key={operation.displayName}>
                    <button
                        className="btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            if (operation.canExecute(props.context)) {
                                operation.execute(props.context);
                            }
                        }}
                    >
                        <span className="gap-2 d-flex align-items-center">
                            {operation.icon.type == "fontawesome" ? (
                                <FontAwesomeIcon icon={operation.icon.icon} />
                            ) : (
                                <img src={isDarkTheme ? operation.icon.darkUrl : operation.icon.lightUrl} />
                            )}
                            {operation.displayName}
                        </span>
                    </button>
                </li>
            ))}
        </ul>
    );
}

export default VizListItemContextMenu;
