import { DataOperation, DataOperationCategory, useVizContext } from "../../../../models/viz/operations/DataOperation";
import { useEffect, useMemo, useState } from "react";
import ConditionalSpinner from "../../../../components/ConditionalSpinner";
import { DataOperationButton } from "../../../viz/components/Shared/DataOperationButton";
import { capitaliseFirst } from "../../../../utilities/helpers";

export interface VideoToolsSidebarProps {
    viewId: string;
    isMediaLoaded: boolean;
    isExpanded: boolean;
    setIsExpanded: (_: React.SetStateAction<boolean>) => void;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
}

const IGNORED_OPERATIONS = ["refreshData"];

function VideoToolsSidebar(props: VideoToolsSidebarProps) {
    const context = useVizContext(props.viewId);
    const hasNoDataDescriptions = (context.view?.dataDescriptions.length ?? 0) == 0;

    // Update loading status only when the media source has been loaded AND view has loaded its data
    const [hasLoaded, setHasLoaded] = useState(false);
    useEffect(() => {
        if (context?.view?.data?.state != "loaded" || !props.isMediaLoaded) {
            setHasLoaded(false);
        } else {
            setHasLoaded(true);
        }
    }, [context?.viewId, context?.view?.data?.state, props.isMediaLoaded]);

    const operations = useMemo(() => {
        const operationsSet = new Set<string>();
        const operationsMap = new Map<DataOperationCategory, DataOperation[]>();

        if (!hasLoaded) {
            return operationsMap;
        }

        context.view?.dataDescriptions
            .flatMap((d) => d.operations)
            .filter((op) => op.context != "entity" && !IGNORED_OPERATIONS.includes(op.operationId))
            .forEach((op) => {
                if (operationsSet.has(op.operationId)) {
                    return;
                } else {
                    operationsSet.add(op.operationId);

                    if (operationsMap.has(op.category)) {
                        operationsMap.get(op.category).push(op);
                    } else {
                        operationsMap.set(op.category, [op]);
                    }
                }
            });

        return operationsMap;
    }, [context?.viewId, hasLoaded]);

    context.container.hasInlineCropSelector = hasLoaded;

    return (
        <div className="video-sidebar video-sidebar-tools">
            <ConditionalSpinner isLoading={!hasLoaded}>
                {hasNoDataDescriptions ? (
                    <div className="p-1">No valid operations available for this media source</div>
                ) : (
                    Array.from(operations).map(([category, operations], i) => (
                        <section key={i}>
                            <h4 className="fs-5">{categoryToDisplayName(category)}</h4>
                            <div>
                                {operations.map((operation) => (
                                    <DataOperationButton
                                        key={operation.operationId}
                                        operation={operation}
                                        context={context}
                                    />
                                ))}
                            </div>
                        </section>
                    ))
                )}
            </ConditionalSpinner>
        </div>
    );
}

function categoryToDisplayName(category: DataOperationCategory) {
    switch (category) {
        case "jump":
            return "Go To";
        default:
            return capitaliseFirst(category);
    }
}

export default VideoToolsSidebar;
