import { useEffect, useState } from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { NotificationItem } from "./NotificationItem";
import { Notification, Notifications } from "./Notifications";
import "./Notifications.scss";

export const NotificationArea = () => {
    const [activeNotifications, setActiveNotifications] = useState<Notification[]>([]);
    const showHeader = activeNotifications.length > 3;

    const removeNotification = (notification: Notification) => {
        Notifications.removeNotification(notification);
    };

    const setNotifications = (notifications: Notification[]) => {
        setActiveNotifications([...notifications]);
    };

    useEffect(() => {
        Notifications.register("notifyarea", setNotifications);
    }, []);

    return (
        <ToastContainer className="bottom-0 py-5 px-3 position-fixed end-0 notification-area">
            <Toast className="mb-3 toasts-header" show={showHeader}>
                <div className="d-flex justify-content-between align-items-center">
                    <span className="align-middle">{activeNotifications.length} notifications</span>
                    <Button variant="outline-primary" size="sm" onClick={() => Notifications.clearNotifications()}>
                        Clear all
                    </Button>
                </div>
            </Toast>

            <div className="gap-3 toast-scroll d-flex flex-column">
                {activeNotifications.map((msg) => (
                    <NotificationItem notification={msg} onNotificationClosed={removeNotification} key={msg.id} />
                ))}
            </div>
        </ToastContainer>
    );
};
