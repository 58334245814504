import { nanoid } from "@reduxjs/toolkit";

const MAX_OLD_NOTIFICATIONS = 100;

export type NotificationSeverity = "info" | "important" | "warning";

export class Notification {
    id: string;
    title: string;
    message: string;
    severity: NotificationSeverity;
    addedAt: Date;
    isOld: boolean = false;

    constructor(title: string, message: string, severity: NotificationSeverity, id: string) {
        this.id = id;
        this.title = title;
        this.message = message;
        this.severity = severity;
        this.addedAt = new Date();
    }

    /** Returns true if a given Notification object is equivalent to this Notification object */
    public isEquivalent(notification: Notification) {
        const { id, message, title, severity } = notification;

        return id == this.id && title == this.title && message == this.message && severity == this.severity;
    }
}

type SetNotificationsFn = (notifications: Notification[]) => void;

export class NotificationService {
    private notifications: Notification[] = [];
    private oldNotifications: Notification[] = [];
    private isShowingOld: boolean = false;
    private setUiNotifications: Map<string, SetNotificationsFn> = new Map();

    /** Add notification(s) to the `oldNotifications` array */
    private addToOld(...notifications: Notification[]) {
        // Limit number of old notifications to be stored
        const exceedsBy = this.oldNotifications.length + notifications.length - MAX_OLD_NOTIFICATIONS;
        if (exceedsBy > 0) {
            this.oldNotifications.splice(this.oldNotifications.length - 1 - exceedsBy, exceedsBy);
        }

        for (const notification of notifications) {
            notification.isOld = true;
            this.oldNotifications.unshift(notification);
        }
    }

    /** Initial setup for the NotificationService to interface with a component */
    public register(key: string, setNotificationsFn: SetNotificationsFn) {
        console.log("Registering notifications");
        this.setUiNotifications.set(key, setNotificationsFn);
        // Display notifications which were set before registration
        this.setUiNotifications.forEach((fn) => fn(this.notifications));
    }

    public notify(title: string, message: string, severity: NotificationSeverity = "info", id?: string) {
        id = id ?? nanoid();
        const notification = new Notification(title, message, severity, id);

        // Check for an existing notification with the same ID
        for (const [index, existingNotification] of this.notifications.entries()) {
            if (existingNotification.id != id) {
                continue;
            }

            // If the new notification is not equivalent to the existing one, replace it in
            // the notifications array, otherwise just reset addedAt
            if (existingNotification.isEquivalent(notification)) {
                existingNotification.addedAt = new Date();
            } else {
                this.addToOld(this.notifications[index]);
                this.notifications[index] = notification;
                this.setUiNotifications.forEach((fn) => fn(this.notifications));
            }

            this.setUiNotifications.forEach((fn) => fn(this.notifications));
            return;
        }

        this.notifications.unshift(notification);
        this.isShowingOld = false;
        this.setUiNotifications.forEach((fn) => fn(this.notifications));
    }

    public removeNotification(notification: Notification) {
        if (notification.isOld) {
            return;
        }

        const notificationIndex = this.notifications.indexOf(notification);
        if (notificationIndex !== -1) {
            this.notifications.splice(notificationIndex, 1);
            this.isShowingOld = false;
            this.setUiNotifications.forEach((fn) => fn(this.notifications));

            notification.isOld = true;
            this.oldNotifications.push(notification);
        }
    }

    public clearNotifications() {
        this.addToOld(...this.notifications);
        this.notifications = [];
        this.isShowingOld = false;
        this.setUiNotifications.forEach((fn) => fn(this.notifications));
    }

    public showOldNotifications() {
        this.clearNotifications();
        this.isShowingOld = true;
        this.setUiNotifications.forEach((fn) => fn(this.notifications));
    }

    /** Returns a boolean based on whether there are currently any active notifications */
    public hasNotifications() {
        return this.notifications.length > 0;
    }

    /** Returns a boolean based on whether there are currently any old notifications */
    public hasOldNotifications() {
        return this.oldNotifications.length > 0;
    }

    /** Returns a boolean based on whether the old notifications are currently being shown in place of newer ones */
    public isShowingOldNotifications() {
        return this.isShowingOld;
    }
}

export const Notifications = new NotificationService();
