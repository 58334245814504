import * as d3 from "d3";
import { d3RectSelection, d3TextSelection, d3GSelection } from "./TimelineTypes";
import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
import { MutableRefObject } from "react";

export class TimelineHoverText {
    cachedPosition: number;
    hoverText: d3TextSelection;
    hoverSubText: d3TextSelection;
    hoverContainer: d3GSelection;
    hoverRect: d3RectSelection;
    paddingY = 5;
    paddingX = 10;
    marginY = 10;
    marginX = 10;

    constructor(timelineRef: MutableRefObject<SVGSVGElement>) {
        this.createHoverText(timelineRef);
    }

    setPosition(position: number, totalWidth: number) {
        const hoverTextWidth = this.hoverText.node().getComputedTextLength();
        const combinedHoverTextWidth = Math.floor(hoverTextWidth + this.hoverSubText.node().getComputedTextLength());

        // Don't update position if it will overlflow the timeline
        if (position + combinedHoverTextWidth + this.paddingX > totalWidth) {
            return;
        }

        this.cachedPosition = position;
        this.hoverRect.attr("width", combinedHoverTextWidth + this.paddingX * 2);
        this.hoverContainer.attr("transform", `translate(${position + this.marginX}, ${this.marginY})`);
        this.hoverSubText.attr("transform", `translate(${hoverTextWidth + this.paddingX}, 0)`);
    }

    setDate(date: Date) {
        const dateTime = dayjs(date);
        this.hoverText.text(dateTime.format(DateFormats.dayMonthYearWithTimeSeconds) + ".");
        this.hoverSubText.text(dateTime.format(DateFormats.milliseconds));
    }

    hide() {
        this.hoverContainer.style("opacity", 0);
    }

    show() {
        this.hoverContainer.style("opacity", 1);
    }

    private createHoverText(timelineRef: MutableRefObject<SVGSVGElement>) {
        const timelineSelection = d3.select(timelineRef.current);

        // Hack to get CSS for monospace font-family i.e. var(--bs-font-monospace)
        const dummyDiv = timelineSelection.append("div").attr("class", "font-monospace");
        const fontFamily = dummyDiv.style("font-family");
        dummyDiv.remove();

        const textHeight = 15;

        this.hoverContainer = timelineSelection.append("g");

        this.hoverRect = this.hoverContainer
            .append("rect")
            .attr("class", "timelineHoverBackground")
            .attr("height", textHeight + this.paddingY * 2);
        this.hoverText = this.hoverContainer
            .append("text")
            .attr("x", this.paddingX)
            .attr("y", textHeight + this.paddingY / 2)
            .style("font", `15px ${fontFamily}`);
        this.hoverSubText = this.hoverContainer
            .append("text")
            .attr("y", textHeight + this.paddingY / 2)
            .style("font", `11px ${fontFamily}`);
    }
}
