import { nanoid } from "@reduxjs/toolkit";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OverlaySpinner from "../../../components/OverlaySpinner";
import useMaxContainerDimensions from "../../../hooks/useMaxContainerDimensions";
import { useView } from "../../../hooks/useView";
import { Entity } from "../../../models/viz/Entity";
import * as AnalyticsStore from "../../../store/analytics/Analytics";
import { AnalyticsStateViewEvent } from "../../../store/analytics/Analytics";
import { useGetStoredFileQuery, useGetTasksQuery } from "../../../store/api/kinesense";
import { Notifications } from "../../../utilities/Notifications/Notifications";
import { isInRange } from "../../../utilities/helpers";
import VideoOverlay from "./VideoOverlay";
import "./VideoPlayer.scss";
import { FragmentsMap } from "./useLoadFragments";
import useMediaSources from "../../../hooks/useMediaSources";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { ApplicationState } from "../../../store";
import useAsyncCallback from "../../../hooks/useAsyncCallback";
import { Spinner } from "react-bootstrap";

/** Helper function for determining whether a given timestamp (in milliseconds) is a valid cursor position */
function isTimestampValid(timestamp: number, videoDuration: number) {
    const notificationId = "invalidTimestamp" as const;

    if (isNaN(timestamp)) {
        Notifications.notify(
            "Invalid timestamp",
            "The specified timestamp is not a valid number",
            "important",
            notificationId,
        );
    } else if (!isInRange(timestamp, [0, videoDuration + 1])) {
        Notifications.notify(
            "Timestamp out of range",
            `The specified timestamp is outside the duration of the current video. Valid timestamps for this video must be within the range 0-${videoDuration}`,
            "important",
            notificationId,
        );
    } else {
        return true;
    }

    return false;
}

export interface VideoPlayerProps {
    videoType: string;
    viewId: string;
    mediaId: string;
    isLoaded: boolean;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
    entitiesInView: Entity[];
    fragmentsMap: MutableRefObject<FragmentsMap>;
    timestampParam?: string | null;
}

/** Handle re-fetching of an active media source which is done being transcoded */
function useHandleTranscoding(mediaId: string, refetchActiveMedia: () => Promise<unknown>) {
    const projectId = useSelector((state: ApplicationState) => state.general?.activeProjectId);
    const { data: tasks } = useGetTasksQuery({ projectId }, { skip: !projectId });

    const prevIsTranscoding = useRef(false);
    const matchingTaskIndex = useMemo(() => {
        return tasks?.findIndex((t) => t.references.mediaId === mediaId);
    }, [tasks]);
    const matchingTask = matchingTaskIndex === undefined ? undefined : tasks[matchingTaskIndex];
    const transcodingStep = matchingTask?.steps.find((s) => s.type == "transcode");
    const isTranscoding = (transcodingStep?.progress ?? 1.0) !== 1.0;

    useAsyncCallback(async () => {
        // Re-fetch active media source if it has just finished transcoding
        if (!isTranscoding && prevIsTranscoding.current) {
            await refetchActiveMedia();
        }
        prevIsTranscoding.current = isTranscoding;
    }, [isTranscoding]);

    return isTranscoding;
}

function VideoPlayer(props: VideoPlayerProps) {
    const dispatch = useDispatch();

    const { view } = useView(props.viewId);
    const { activeMediaSource, refetchActiveMedia } = useMediaSources(props.viewId, props.mediaId);

    const isTranscoding = useHandleTranscoding(props.mediaId, refetchActiveMedia);

    const allowVideoStretching = view?.allowVideoStretching ?? true;
    const scale = allowVideoStretching ? 2 : 1;

    const fileId = activeMediaSource?.files?.initialDisplay?.fileId;
    const {
        data: storedFile,
        isSuccess,
        isError,
        isFetching,
        refetch: refetchStoredFile,
    } = useGetStoredFileQuery({ fileId }, { skip: fileId === undefined || !props.isLoaded });
    const hasLoadedStoredFile = isSuccess && !isFetching;
    const hasNoStoredFile = isError || (!fileId && props.isLoaded);
    const storedFileExpiresAt = storedFile?.accessUrl?.expiresAt;
    const storedFileUrl = storedFile?.accessUrl?.url;

    const [hasLoadedVideo, setHasLoadedVideo] = useState(false);

    const canvasRef = useRef<HTMLCanvasElement>(undefined);
    const isPlaying = useRef(false);
    const isMuted = useRef(false);
    const playerId = useRef("vp-" + nanoid(8));

    const isLoadingMedia = !props.isLoaded || !hasLoadedVideo;

    // For calculating video dimensions
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, containerHeight] = useMaxContainerDimensions(containerRef);
    const srcHeight = props.videoRef.current?.videoHeight;
    const srcWidth = props.videoRef.current?.videoWidth;

    // Controlled video dimensions
    const videoDimensions: [number, number] = useMemo(() => {
        if (!srcWidth || !srcHeight || !containerWidth || !containerHeight) {
            return [0, 0];
        }

        const aspectRatio = srcWidth / srcHeight;

        const maxWidth = Math.min(srcWidth * scale, containerWidth);
        const maxHeight = Math.min(srcHeight * scale, containerHeight);

        // By default, use max height with a matching width
        let videoHeight = maxHeight;
        let videoWidth = aspectRatio * videoHeight;

        // If the width is too big, reduce the dimensions accordingly
        if (videoWidth > maxWidth) {
            videoWidth = maxWidth;
            videoHeight = videoWidth / aspectRatio;
        }

        return [videoWidth, videoHeight];
    }, [containerWidth, containerHeight, srcWidth, srcHeight, allowVideoStretching]);

    useEffect(() => {
        if (!view || view.meta.updateSource == playerId.current || !props.isLoaded || !props.videoRef.current) {
            return;
        }

        switch (view.meta.event) {
            case AnalyticsStateViewEvent.PlayState:
                if (view.mediaStates.play == "paused" && isPlaying.current) {
                    pause();
                }

                if (view.mediaStates.play == "playing" && !isPlaying.current) {
                    play();
                }
                break;
            case AnalyticsStateViewEvent.AudioState:
                if (view.mediaStates.audio == "unmuted" && isMuted.current) {
                    unmute();
                }

                if (view.mediaStates.audio == "muted" && !isMuted.current) {
                    mute();
                }
                break;
            case AnalyticsStateViewEvent.CursorPosition: {
                if (!view.cursor) {
                    break;
                }

                const startTime = activeMediaSource.startsAt;
                const frameTime = (view.cursor - startTime) / 1000;

                if (frameTime != props.videoRef.current.currentTime) {
                    props.videoRef.current.currentTime = frameTime;
                }

                break;
            }
        }
    }, [view, isLoadingMedia, props.videoRef.current]);

    function videoPlayerOnTimeUpdate() {
        const frameTime = props.videoRef.current.currentTime * 1000;
        const startTime = activeMediaSource.startsAt;
        const updatedCursorTime = startTime + frameTime;

        if (updatedCursorTime != view.cursor) {
            dispatch(
                AnalyticsStore.actionCreators.setCursorPosition(
                    props.viewId,
                    "primary",
                    updatedCursorTime,
                    playerId.current,
                ),
            );
        }
    }

    function play() {
        props.videoRef.current.play();
        isPlaying.current = true;
    }

    function pause() {
        props.videoRef.current.pause();
        isPlaying.current = false;
    }

    function mute() {
        props.videoRef.current.muted = true;
        isMuted.current = true;
    }

    function unmute() {
        props.videoRef.current.muted = false;
        isMuted.current = false;
    }

    function resetVideoUrl() {
        URL.revokeObjectURL(props.videoRef.current.src);
        props.videoRef.current.src = storedFileUrl;
    }

    function loadVideo() {
        resetVideoUrl();

        let newCursorTime = activeMediaSource.startsAt;

        if (hasLoadedVideo) {
            setHasLoadedVideo(false);
        }
        // Set video start time to the provided timestamp, if this is the first video load and if it is valid
        else if (props.timestampParam) {
            const timestamp = parseInt(props.timestampParam);
            if (isTimestampValid(timestamp, activeMediaSource.duration)) {
                newCursorTime += timestamp;
            }
        }

        dispatch(
            AnalyticsStore.actionCreators.setCursorPosition(props.viewId, "primary", newCursorTime, playerId.current),
        );

        props.videoRef.current.load();
        props.videoRef.current.currentTime = (newCursorTime - activeMediaSource.startsAt) / 1000;
    }

    useEffect(() => {
        if (!hasLoadedStoredFile || storedFileUrl === undefined || props.viewId === undefined) {
            return;
        }

        loadVideo();

        const videoHasLoadedCallback = () => setHasLoadedVideo(true);
        props.videoRef.current.addEventListener("loadeddata", videoHasLoadedCallback, true);

        return () => {
            props.videoRef?.current?.removeEventListener("loadeddata", videoHasLoadedCallback);
        };
    }, [hasLoadedStoredFile, storedFileUrl]);

    useEffect(() => {
        if (storedFileExpiresAt === undefined || props.viewId === undefined) {
            return;
        }

        const millisecondsToExpiration = storedFile.accessUrl.expiresAt - Date.now();

        // If expiration time has already passed, refetch stored file
        if (millisecondsToExpiration <= 0) {
            refetchStoredFile();
            return;
        }

        // Handle future expiry by refetching stored file before expiration time
        const timeout = setTimeout(refetchStoredFile, millisecondsToExpiration - 10000);

        return () => {
            clearTimeout(timeout);
        };
    }, [storedFileExpiresAt]);

    return (
        <div className="video-player d-flex align-items-center justify-content-center" ref={containerRef}>
            <OverlaySpinner
                isLoading={(isLoadingMedia || !hasLoadedStoredFile) && !hasNoStoredFile && !isTranscoding}
            />

            <div className="video-container">
                {isTranscoding ? (
                    <div role="status" className="gap-3 p-3 rounded h6 no-playable-media d-flex align-items-center">
                        <Spinner size="sm" />
                        <p className="m-0">The media source is being transcoded</p>
                    </div>
                ) : hasNoStoredFile ? (
                    <div role="status" className="gap-3 p-3 rounded h6 no-playable-media d-flex align-items-center">
                        <FontAwesomeIcon icon={faVideoSlash} />
                        <p className="m-0">No playable media found for this source</p>
                    </div>
                ) : (
                    <>
                        <video
                            width={videoDimensions[0]}
                            height={videoDimensions[1]}
                            role="region"
                            aria-label="video player"
                            ref={props.videoRef}
                        />

                        {!isLoadingMedia && (
                            <VideoOverlay
                                canvasRef={canvasRef}
                                videoRef={props.videoRef}
                                viewId={props.viewId}
                                media={activeMediaSource}
                                videoPlayerOnTimeUpdate={videoPlayerOnTimeUpdate}
                                fragmentsMap={props.fragmentsMap}
                                entitiesInView={props.entitiesInView}
                                maxDimensions={videoDimensions}
                                isPlaying={isPlaying}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default VideoPlayer;
