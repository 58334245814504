import { useDispatch } from "react-redux";
import { ViewRepresentation } from "../../../../store/analytics/Analytics";
import { useView } from "../../../../hooks/useView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faTh } from "@fortawesome/free-solid-svg-icons";

export interface RepresentationSelectorProps {
    viewId: string;
}

export function RepresentationSelector(props: RepresentationSelectorProps) {
    const dispatch = useDispatch();
    const { view } = useView(props.viewId);
    const representation = view?.representation;

    const setRepresentation = (rep: ViewRepresentation) => {
        dispatch({
            type: "analytics/setViewRepresentation",
            payload: {
                viewId: props.viewId,
                representation: rep,
            },
        });
    };

    return (
        <div className="multi-button">
            <a
                title="Icons"
                className={`btn-viz-data-description${representation === "large-icons" ? " selected" : ""}`}
                onClick={() => setRepresentation("large-icons")}
            >
                <span>
                    <FontAwesomeIcon icon={faTh} />
                </span>
            </a>
            <a
                title="Detail list"
                className={`btn-viz-data-description${representation === "detail-list" ? " selected" : ""}`}
                onClick={() => setRepresentation("detail-list")}
            >
                <span>
                    <FontAwesomeIcon icon={faList} />
                </span>
            </a>
        </div>
    );
}
