import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { timestampJsonReviver } from "cloud-core/utilities/json";
import { ApplicationState } from "../";
import { Organisation } from "../../models/Organisation";
import { Role, User, convertFetchedUsers } from "../../models/User";
import { UserConfig } from "../../models/UserConfig";
import { Processor, ProcessorTypes } from "../../models/admin/Processor";
import { AnalyticsDefinition, AnalyticsMetadata } from "../../models/analysis/Config";
import { Run } from "../../models/analysis/Runs";
import { IntegrationProject } from "../../models/integrations/IntegrationProject";
import { AnalysisRequest } from "../../models/media/AnalysisRequest";
import { FetchedFragmentGroup } from "../../models/media/FragmentGroup";
import {
    AddMediaSourceRequestBody,
    IntegrationMediaSource,
    MediaSource,
    sortMediaSources,
} from "../../models/media/MediaSource";
import { addAuthHeaders } from "../../utilities/Auth";
import { actionCreators } from "../user/User";
import { StoredFile } from "../../models/media/StoredFile";
import { FragmentsMap } from "../../views/playback/components/useLoadFragments";
import { MediaSourceWithRunSummariesAndEndsAt } from "../media/MediaItems";
import { Project } from "../../models/Project";
import { TagObject } from "../../models/tags/TagObject";
import { TagEvent } from "../../models/tags/TagEvent";
import { fetchAllPages } from "./utils";
import VideoClip, { VideoClipRequest } from "../../models/media/VideoClip";
import { Task } from "../../models/tasks/Task";

const TEXT_PLAIN_HEADER = { "Content-Type": "text/plain" } as const;

export interface BaseAdminQueryArgs {
    organisationId: string;
}
export type BaseAugmentationArgs = { organisationId?: string };
export type AugmentationWithPayloadArgs = BaseAugmentationArgs & { payload: string };
export type AugmentUserArgs = BaseAugmentationArgs & { username: string };
export type ProjectArgs = { projectId: string };
export type MediaSourceArgs = { mediaId: string };
export type ProjectMediaSourceArgs = ProjectArgs & MediaSourceArgs;

const tagTypes = [
    "userConfig",
    "projects",
    "tasks",
    "users",
    "organisation",
    "organisations",
    "media",
    "processors",
    "fragmentGroups",
    "fragments",
    "runs",
    "tagObjects",
    "tagEvents",
    "analyticsMetadata",
    "tagObjects",
    "tagEvents",
] as const;
type TagType = (typeof tagTypes)[number];
interface Tag {
    type: TagType;
    id: string;
}

const usersTag: Tag = { type: "users", id: "0" };
const getUsersAdminTag = (args: BaseAugmentationArgs) => ({ type: "users", id: args.organisationId }) as Tag;

const baseQuery = fetchBaseQuery({
    baseUrl: `https://${process.env.REACT_APP_KINESENSE_API_BASE}`,
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as ApplicationState;

        if (state && state.user !== undefined) {
            addAuthHeaders(state.user.authToken, headers);
        }

        return headers;
    },
});

export interface KinesenseApiResponse<T> {
    data: T;
    hasNextPage: boolean;
    pageKey: string | undefined;
    error: FetchBaseQueryError;
}

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error?.status === 401) {
        api.dispatch(actionCreators.refreshAuthSession());

        result = await baseQuery(args, api, extraOptions);

        if (result.error?.status === 401) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            result = await baseQuery(args, api, extraOptions);
        }
    }

    if (result.error || !result.data) {
        return result;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return result.data as any;
};

export const kinesenseApiSlice = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReauth,
    tagTypes: tagTypes,
    endpoints: (builder) => ({
        // PROJECTS
        getProjects: builder.query<Project[], void>({
            async queryFn(_args, _queryApi, _extraOptions, fetchWithBQ) {
                const { data, error } = await fetchAllPages<Project[]>(fetchWithBQ, "/projects");
                if (error !== undefined) {
                    return { error };
                }
                return { data };
            },
            providesTags: ["projects"],
        }),
        getProject: builder.query<Project, ProjectArgs>({
            query: (args) => `/projects/${args.projectId}`,
        }),
        getProjectThumbnails: builder.query<
            { thumbnails: Record<string, string>; expiresAt: number },
            ProjectArgs & { pageKey?: string }
        >({
            query: (args) =>
                `/projects/${args.projectId}/media/get-thumbnails` + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
        }),
        addProject: builder.mutation<Project, { payload: string }>({
            query: (args) => ({
                url: "/projects",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                // Payload already stringified
                body: args.payload,
            }),
            invalidatesTags: ["projects"],
        }),
        // TASKS
        getTasks: builder.query<Task[], ProjectArgs>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const { data, error } = await fetchAllPages<Task[]>(fetchWithBQ, `/projects/${args.projectId}/tasks`);
                if (error !== undefined) {
                    return { error };
                }

                const now = new Date().getTime();
                const limit_ms = 2 * 24 * 60 * 60 * 1000;

                return {
                    data: data
                        // Only show recent tasks
                        .filter((t) => t.startedAt >= now - limit_ms)
                        // Sort by `startedAt`, latest tasks should come first
                        .sort((a, b) => b.startedAt - a.startedAt),
                };
            },
            providesTags: ["tasks"],
        }),
        getTask: builder.query<Project, ProjectArgs & { taskId: string }>({
            query: (args) => `/projects/${args.projectId}/tasks/${args.taskId}`,
        }),
        // USERS
        getCurrentUserConfig: builder.query<UserConfig, void>({
            query: () => "/user/config/general",
            providesTags: ["userConfig"],
        }),
        updateCurrentUserConfig: builder.mutation<void, Partial<UserConfig>>({
            query: (args) => ({ url: "/user/config/general", method: "PATCH", body: JSON.stringify(args) }),
            invalidatesTags: ["userConfig"],
        }),

        getUsers: builder.query<User[], { pageKey?: string }>({
            query: (args) => {
                return "/organisation/users" + (args?.pageKey ? `?pageKey=${args.pageKey}` : "");
            },
            transformResponse: convertFetchedUsers,
            providesTags: [usersTag],
        }),
        adminGetUsers: builder.query<User[], BaseAdminQueryArgs & { pageKey?: string }>({
            query: (args) =>
                `/admin/organisations/${args.organisationId}/users` + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            transformResponse: convertFetchedUsers,
            providesTags: (_, __, admin_query_args) => [getUsersAdminTag(admin_query_args)],
        }),

        addUser: builder.mutation<unknown, AugmentationWithPayloadArgs>({
            query: (args) => ({
                url: "/organisation/users",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                // Payload already stringified
                body: args.payload,
            }),
            invalidatesTags: [usersTag],
        }),
        adminAddUser: builder.mutation<unknown, AugmentationWithPayloadArgs>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                // Payload already stringified
                body: args.payload,
            }),
            invalidatesTags: (_, __, augmentation_args) => [getUsersAdminTag(augmentation_args)],
        }),

        deleteUser: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/organisation/users/${args.username}`,
                method: "DELETE",
            }),
            invalidatesTags: [usersTag],
        }),
        adminDeleteUser: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users/${args.username}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, augment_user_args) => [getUsersAdminTag(augment_user_args)],
        }),

        resetUserPassword: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/organisation/users/${args.username}/reset-password`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
            }),
            invalidatesTags: [usersTag],
        }),
        adminResetUserPassword: builder.mutation<void, AugmentUserArgs>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users/${args.username}/reset-password`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
            }),
            invalidatesTags: (_, __, augment_user_args) => [getUsersAdminTag(augment_user_args)],
        }),

        adminSetUserRole: builder.mutation<void, AugmentUserArgs & { body: { role: Role } }>({
            query: (args) => ({
                url: `/admin/organisations/${args.organisationId}/users/${args.username}/role`,
                method: "PUT",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.body),
            }),
            invalidatesTags: (_, __, augment_user_args) => [getUsersAdminTag(augment_user_args)],
        }),

        // ORGANISATIONS
        getOrganisation: builder.query<Organisation, void>({
            query: () => "/organisation",
            providesTags: ["organisation"],
        }),

        adminGetOrganisations: builder.query<Organisation[], { pageKey?: string }>({
            query: (args) => "/admin/organisations" + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: ["organisations"],
        }),

        adminAddOrganisation: builder.mutation<Organisation, AugmentationWithPayloadArgs>({
            query: (args) => ({
                url: "/admin/organisations",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: args.payload,
            }),
            invalidatesTags: ["organisations"],
        }),

        // MEDIA
        getAllMedia: builder.query<MediaSource[], ProjectArgs>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const { data, error } = await fetchAllPages<MediaSource[]>(
                    fetchWithBQ,
                    `/projects/${args.projectId}/media`,
                );
                if (error !== undefined) {
                    return { error };
                }

                data.sort(sortMediaSources);

                return { data };
            },
            providesTags: ["media"],
        }),
        addMediaSource: builder.mutation<MediaSource, AddMediaSourceRequestBody>({
            query: (mediaSource) => ({
                url: `/projects/${mediaSource.projectId}/media`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(mediaSource),
                responseHandler: (response) =>
                    response
                        .text()
                        .then((txt) => JSON.parse(txt, timestampJsonReviver(["startsAt", "createdAt", "updatedAt"]))),
            }),
            invalidatesTags: ["media", "projects"],
        }),
        getMediaSource: builder.query<MediaSourceWithRunSummariesAndEndsAt, ProjectMediaSourceArgs>({
            query: (args) => `/projects/${args.projectId}/media/${args.mediaId}`,
            transformResponse: (fetchedMediaSource: MediaSourceWithRunSummariesAndEndsAt) => {
                fetchedMediaSource.endsAt = fetchedMediaSource.startsAt + fetchedMediaSource.duration;
                return fetchedMediaSource;
            },
        }),

        getStoredFile: builder.query<StoredFile, { fileId: string }>({
            query: (args) => `/files/${args.fileId}`,
        }),

        getVideoClips: builder.query<VideoClip[], ProjectMediaSourceArgs>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const { data, error } = await fetchAllPages<VideoClip[]>(
                    fetchWithBQ,
                    `/projects/${args.projectId}/media/${args.mediaId}/clips`,
                );
                if (error !== undefined) {
                    return { error };
                }

                // Ensure clips are sorted by their creation time
                data.sort((a, b) => b.createdAt - a.createdAt);

                return { data };
            },
        }),
        addVideoClip: builder.mutation<
            {
                fileId: string;
                jobId: string;
            },
            ProjectMediaSourceArgs & { payload: VideoClipRequest }
        >({
            query: (args) => ({
                url: `/projects/${args.projectId}/media/${args.mediaId}/clips`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.payload),
            }),
        }),

        // INTEGRATIONS
        getAllIntegrationMedia: builder.query<IntegrationMediaSource[], { integrationId: string }>({
            query: (args) => `/integrations/${args.integrationId}/media`,
        }),
        requestIntegrationTransfer: builder.mutation<
            void,
            MediaSourceArgs & { integrationId: string; integrationMediaId: string }
        >({
            query: (args) => ({
                url: `/integrations/${args.integrationId}/start-transfer`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify({
                    integrationMediaId: args.integrationMediaId,
                    linkedObjectId: args.mediaId,
                    linkedObjectType: "mediasource",
                }),
            }),
        }),
        getAllIntegrationProjects: builder.query<IntegrationProject[], { integrationId: string }>({
            query: (args) => `/integrations/${args.integrationId}/projects`,
        }),
        exportMediaToIntegration: builder.mutation<
            string,
            ProjectMediaSourceArgs & {
                integrationId: string;
                title: string;
                description: string;
                startsAt: number;
                endsAt: number;
                integrationProjectId: string;
                folderId: string;
            }
        >({
            query: (args) => ({
                url: `/projects/${args.projectId}/media/${args.mediaId}/export-clip`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                responseHandler: (response) => response.text(),
                body: JSON.stringify({
                    integrationId: args.integrationId,
                    title: args.title,
                    description: args.description,
                    startsAt: args.startsAt,
                    endsAt: args.endsAt,
                    projectId: args.integrationProjectId,
                    folderId: args.folderId,
                }),
            }),
        }),

        // ANALYSIS
        getRuns: builder.query<Run[], MediaSourceArgs>({
            query: (args) => `/media/${args.mediaId}/analytics/runs`,
            providesTags: (_result, _error, args) => [{ type: "runs", id: args.mediaId }],
        }),
        getFragmentsFromGroups: builder.query<
            FragmentsMap,
            MediaSourceArgs & { runId: number; groupIds: [string, ...string[]] }
        >({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const { data, error } = await fetchAllPages<FragmentsMap>(
                    fetchWithBQ,
                    `/media/${args.mediaId}/analytics/runs/${args.runId}/groups/fragments?groupId=${args.groupIds.join(
                        ",",
                    )}`,
                );
                if (error !== undefined) {
                    return { error };
                }

                // Sort each array of fragments by their frameTimeOffset
                for (const groupId in data) {
                    data[groupId].sort((a, b) => a.frameTimeOffset - b.frameTimeOffset);
                }

                return { data };
            },
            providesTags: (_result, _error, args) => [
                { type: "fragments", id: `${[...args.groupIds].sort((a, b) => a.localeCompare(b)).join(",")}` },
            ],
        }),
        getFragmentGroups: builder.query<FetchedFragmentGroup[], MediaSourceArgs & { runId: number }>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const { data, error } = await fetchAllPages<FetchedFragmentGroup[]>(
                    fetchWithBQ,
                    `/media/${args.mediaId}/analytics/runs/${args.runId}/groups`,
                );
                if (error !== undefined) {
                    return { error };
                }

                // Ensure groups are sorted by starting offset
                data.sort((a, b) => a.startOffset - b.startOffset);

                return { data };
            },
            providesTags: (_result, _error, args) => [{ type: "fragmentGroups", id: `${args.mediaId}-${args.runId}` }],
            keepUnusedDataFor: 180,
        }),
        getFragmentImageCookies: builder.query<Record<string, string>, MediaSourceArgs>({
            query: (args) => ({
                url: `/media/${args.mediaId}/get-image-cookies`,
                method: "GET",
                headers: TEXT_PLAIN_HEADER,
                credentials: "include",
            }),
        }),
        getAnalysersConfig: builder.query<AnalyticsDefinition, void>({
            query: () => "/organisation/config/analysers",
        }),
        getAnalyticsMetadata: builder.query<AnalyticsMetadata, { pageKey?: string }>({
            query: (args) => "/organisation/config/aMetadata" + (args.pageKey ? `?pageKey=${args.pageKey}` : ""),
            providesTags: ["analyticsMetadata"],
        }),
        setAnalyticsMetadata: builder.mutation<void, AnalyticsMetadata>({
            query: (args) => ({ url: "/organisation/config/aMetadata", method: "PUT", body: JSON.stringify(args) }),
        }),
        deleteAnalyticsMetadataGroup: builder.mutation<void, { groupId: string }>({
            query: (args) => ({ url: `/organisation/config/aMetadata/${args.groupId}`, method: "DELETE" }),
        }),
        requestAnalysis: builder.mutation<Run, MediaSourceArgs & { analysisRequest: AnalysisRequest }>({
            query: (args) => ({
                url: `/media/${args.mediaId}/queue-analysis`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.analysisRequest),
            }),
        }),
        linkFileToMediaSource: builder.mutation<void, ProjectMediaSourceArgs & { fileId: string }>({
            query: (args) => ({
                url: `/projects/${args.projectId}/media/${args.mediaId}/link-file`,
                method: "POST",
                body: JSON.stringify({ fileId: args.fileId }),
            }),
        }),

        // TAGS
        getAllTagObjects: builder.query<TagObject[], ProjectArgs>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                const { data, error } = await fetchAllPages<TagObject[]>(
                    fetchWithBQ,
                    `/projects/${args.projectId}/tags/objects`,
                );
                if (error !== undefined) {
                    return { error };
                }
                return { data };
            },
            providesTags: ["tagObjects"],
        }),
        createTagObject: builder.mutation<TagObject, ProjectArgs & { tagObject: Partial<TagObject> }>({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/objects`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagObject),
            }),
            invalidatesTags: ["tagObjects"],
        }),
        updateTagObject: builder.mutation<
            TagObject,
            ProjectArgs & { tagObjectId: string; tagObject: Partial<TagObject> }
        >({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/objects/${args.tagObjectId}`,
                method: "PATCH",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagObject),
            }),
            invalidatesTags: ["tagObjects"],
        }),
        getAllTagEvents: builder.query<TagEvent[], ProjectArgs>({
            async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
                // Get all possible tag objects, used for extending the tag events
                const { data: objects, error: objectsError } = await fetchAllPages<TagObject[]>(
                    fetchWithBQ,
                    `/projects/${args.projectId}/tags/objects`,
                );
                if (objectsError !== undefined) {
                    return { error: objectsError };
                }

                const { data, error } = await fetchAllPages<TagEvent[]>(
                    fetchWithBQ,
                    `/projects/${args.projectId}/tags/events`,
                );
                if (error !== undefined) {
                    return { error };
                }

                // Extend tag events with associated tag objects
                for (const event of data) {
                    event.subjects = [];
                    event.objects = [];
                    event.locations = [];
                    for (const object of objects) {
                        if (event.subjectIds.includes(object.objectId)) {
                            event.subjects.push(object);
                        } else if (event.objectIds.includes(object.objectId)) {
                            event.objects.push(object);
                        } else if (event.locationIds.includes(object.objectId)) {
                            event.locations.push(object);
                        }
                    }
                }

                return { data };
            },
            providesTags: ["tagEvents"],
        }),
        createTagEvent: builder.mutation<TagEvent, ProjectArgs & { tagEvent: Partial<TagEvent> }>({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/events`,
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagEvent),
            }),
            invalidatesTags: ["tagEvents"],
        }),
        updateTagEvent: builder.mutation<TagEvent, ProjectArgs & { tagEventId: string; tagEvent: Partial<TagEvent> }>({
            query: (args) => ({
                url: `/projects/${args.projectId}/tags/events/${args.tagEventId}`,
                method: "PATCH",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args.tagEvent),
            }),
            invalidatesTags: ["tagEvents"],
        }),

        // PROCESSORS
        adminGetProcessor: builder.query<Processor, { processorId: string }>({
            query: (args) => `/analysis/processors/${args.processorId}`,
        }),

        adminGetAllProcessors: builder.query<Processor[], void>({
            query: () => "/analysis/processors",
            providesTags: ["processors"],
        }),

        adminGetProcessorTypes: builder.query<ProcessorTypes, void>({
            query: () => "/analysis/processor-types",
        }),

        adminAddProcessor: builder.mutation<Processor, { type: string }>({
            query: (args) => ({
                url: "/analysis/processors",
                method: "POST",
                headers: TEXT_PLAIN_HEADER,
                body: JSON.stringify(args),
            }),
            invalidatesTags: ["processors"],
        }),
    }),
});

export const {
    endpoints,
    useGetProjectsQuery,
    useGetProjectQuery,
    useGetProjectThumbnailsQuery,
    useGetTasksQuery,
    useGetTaskQuery,
    useAddProjectMutation,
    useLazyGetCurrentUserConfigQuery,
    useGetCurrentUserConfigQuery,
    useGetUsersQuery,
    useUpdateCurrentUserConfigMutation,
    useAdminGetUsersQuery,
    useAddUserMutation,
    useAdminAddUserMutation,
    useDeleteUserMutation,
    useAdminDeleteUserMutation,
    useResetUserPasswordMutation,
    useAdminResetUserPasswordMutation,
    useGetOrganisationQuery,
    useAdminGetOrganisationsQuery,
    useAdminAddOrganisationMutation,
    useGetAllMediaQuery,
    useLazyGetAllMediaQuery,
    useGetStoredFileQuery,
    useLazyGetStoredFileQuery,
    useAdminGetAllProcessorsQuery,
    useAdminGetProcessorQuery,
    useAdminGetProcessorTypesQuery,
    useAdminAddProcessorMutation,
    useAdminSetUserRoleMutation,
    useLazyGetFragmentGroupsQuery,
    useGetFragmentGroupsQuery,
    useGetFragmentImageCookiesQuery,
    useLazyGetFragmentImageCookiesQuery,
    useLazyGetFragmentsFromGroupsQuery,
    useGetAnalysersConfigQuery,
    useGetAnalyticsMetadataQuery,
    useSetAnalyticsMetadataMutation,
    useDeleteAnalyticsMetadataGroupMutation,
    useGetRunsQuery,
    useLazyGetRunsQuery,
    useAddMediaSourceMutation,
    useRequestAnalysisMutation,
    useGetAllIntegrationMediaQuery,
    useRequestIntegrationTransferMutation,
    useGetAllIntegrationProjectsQuery,
    useExportMediaToIntegrationMutation,
    useGetMediaSourceQuery,
    useLazyGetMediaSourceQuery,
    useGetAllTagObjectsQuery,
    useCreateTagObjectMutation,
    useUpdateTagObjectMutation,
    useGetAllTagEventsQuery,
    useCreateTagEventMutation,
    useUpdateTagEventMutation,
    useLinkFileToMediaSourceMutation,
    useGetVideoClipsQuery,
    useLazyGetVideoClipsQuery,
    useAddVideoClipMutation,
} = kinesenseApiSlice;
