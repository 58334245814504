import { Task as TaskBase, TaskStep } from "cloud-core/tasks/Task";

export class Task extends TaskBase<number> {}

export function getTaskProgress(task: Task) {
    if (!task?.steps?.length) {
        return 0;
    }

    if (task.isComplete || task.isFailed) {
        return 100;
    }

    let [totalWeight, totalProgress] = [0, 0];
    for (const step of task.steps) {
        totalWeight += step.weight;
        totalProgress += step.progress * step.weight;
    }

    if (!totalWeight || !totalProgress) {
        return 0;
    }

    return Math.min(Math.round((totalProgress / totalWeight) * 100), 99);
}

function getTaskStepStatusMessage(taskStep: TaskStep | undefined) {
    switch (taskStep?.status) {
        case "starting":
            return "Preparing...";
        case "running":
            return "In progress...";
        case "complete":
            return "Completed";
        default:
            return "Waiting";
    }
}

export function getTaskStepName(taskStep: TaskStep | undefined) {
    switch (taskStep?.type) {
        case "transcode":
            return "Transcoding";
        default:
            return "Analysis";
    }
}

export function getTaskStepMessage(taskStep: TaskStep | undefined) {
    return `${getTaskStepName(taskStep)} - ${getTaskStepStatusMessage(taskStep)}`;
}
