import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ErrorPage from "../../components/ErrorPage";
import useUserConfig from "../../hooks/useUserConfig";
import { useView } from "../../hooks/useView";
import { calculateFramePeriod } from "../../models/media/MediaSource";
import { ApplicationState } from "../../store";
import { isInRange } from "../../utilities/helpers";
import NoMediaSourcesErrorPage from "../import/components/NoMediaSourcesErrorPage";
import VideoControls, { useVideoControls } from "../playback/components/VideoControls";
import VideoPlayer from "../playback/components/VideoPlayer";
import VideoPlayerHeader from "../playback/components/VideoPlayerHeader";
import useLoadFragments from "../playback/components/useLoadFragments";
import Timeline from "../timeline/components/Timeline";
import "./Review.scss";
import VideoOptionsSidebar from "../playback/components/videoOptionsSidebar/VideoOptionsSidebar";
import VideoToolsSidebar from "../playback/components/videoToolsSidebar/VideoToolsSidebar";
import ReviewVideoList from "./components/ReviewVideoList";
import { useManageViews } from "../../hooks/useManageViews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

function Review() {
    const { userConfig } = useUserConfig();
    const {
        isInvalidMediaId,
        mediaIdParam,
        timestampParam,
        mediaId,
        setMediaId,
        hasLoadedActiveMedia,
        activeMediaSource,
        isMediaSourcesEmpty,
    } = useManageViews();

    const [isLeftSidebarExpanded, setIsLeftSidebarExpanded] = useState(false);
    const [isRightSidebarExpanded, setIsRightSidebarExpanded] = useState(true);
    const [isVideoListExpanded, setIsVideoListExpanded] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(undefined);

    const { analytics } = useSelector((state: ApplicationState) => state);
    const viewId = analytics?.primaryViewId;
    const { view } = useView(viewId);

    const entitiesInView = useMemo(() => {
        if (!view?.filteredEntities) {
            return [];
        }
        const millisecondsBetweenFrames = calculateFramePeriod(activeMediaSource?.files?.initialDisplay);
        return view.filteredEntities.filter((entity) =>
            isInRange(view.cursor, [
                entity.getStartTimeOrElse(() => new Date()).valueOf(),
                entity.getEndTimeOrElse(() => new Date()).valueOf() + millisecondsBetweenFrames,
            ]),
        );
    }, [view?.filteredEntities, view?.cursor]);

    const fragmentsMap = useLoadFragments(mediaId, viewId);
    const areFragmentGroupsLoaded = view?.filteredEntities !== undefined && view?.data?.state === "loaded";

    const videoSyncControlId = useRef(`vsc-${nanoid(8)}`);
    const { play, pause, mute, unmute, isPlaying } = useVideoControls(
        viewId,
        mediaId,
        videoRef,
        videoSyncControlId,
        view?.filteredEntities,
        entitiesInView,
    );

    // Sync video element's play and volume state with analytics store
    // Only needed because picture-in-picture mode has video controls
    useEffect(() => {
        if (videoRef?.current === null || activeMediaSource === undefined) {
            return;
        }

        // For syncing analytics media's muted state with video element's muted state
        function handleVolumeChange(e: Event) {
            (e.currentTarget as HTMLVideoElement).muted ? mute() : unmute();
        }

        videoRef.current?.addEventListener("play", play);
        videoRef.current?.addEventListener("pause", pause);
        videoRef.current?.addEventListener("volumechange", handleVolumeChange);

        return () => {
            pause();
            unmute();

            videoRef.current?.removeEventListener("play", play);
            videoRef.current?.removeEventListener("pause", pause);
            videoRef.current?.removeEventListener("volumechange", handleVolumeChange);
        };
    }, [videoRef?.current, mediaId]);

    if (isInvalidMediaId) {
        return (
            <ErrorPage
                subTitle="Invalid media source ID"
                errorMessageElement={
                    <p>
                        A valid media source with the ID{" "}
                        <code className="py-1 px-2 mx-1 bg-body-secondary">{mediaIdParam}</code> could not be found
                    </p>
                }
            />
        );
    } else if (isMediaSourcesEmpty) {
        return <NoMediaSourcesErrorPage />;
    }

    function toggleMediaList() {
        setIsVideoListExpanded((curr) => !curr);
    }

    function toggleLeftSidebar() {
        setIsLeftSidebarExpanded((curr) => !curr);
    }

    function toggleRightSidebar() {
        setIsRightSidebarExpanded((curr) => !curr);
    }

    return (
        <main className="review video-list-expanded">
            <section className="review-panel review-header">
                <VideoPlayerHeader
                    isMediaLoaded={hasLoadedActiveMedia}
                    viewId={viewId}
                    mediaId={mediaId}
                    videoRef={videoRef}
                />
            </section>

            <section
                className={`review-panel review-video-list ${
                    isVideoListExpanded ? "review-video-list-expanded" : "review-video-list-unexpanded"
                }`}
                onClick={() => isVideoListExpanded || toggleMediaList()}
            >
                <div
                    className="expand-button"
                    style={{ justifyContent: "flex-end" }}
                    onClick={() => (isVideoListExpanded ? toggleMediaList() : null)}
                >
                    <span>
                        <FontAwesomeIcon icon={isVideoListExpanded ? faAngleDoubleLeft : faAngleDoubleRight} />
                    </span>
                </div>

                <div className="review-sidebar-unexpanded-text">
                    <span>V</span>
                    <span>i</span>
                    <span>d</span>
                    <span>e</span>
                    <span>o</span>
                    <span>s</span>
                </div>

                <div className={`review-video-list-content-${isVideoListExpanded ? "expanded" : "unexpanded"}`}>
                    <ReviewVideoList
                        viewId={viewId}
                        mediaId={mediaId}
                        setMediaId={setMediaId}
                        isExpanded={isVideoListExpanded}
                        setIsExpanded={setIsVideoListExpanded}
                    />
                </div>
            </section>

            <section
                className={`review-panel review-sidebar-left ${
                    isLeftSidebarExpanded ? "review-sidebar-left-expanded" : "review-sidebar-left-unexpanded"
                }`}
                onClick={() => isLeftSidebarExpanded || toggleLeftSidebar()}
            >
                <div className={`review-sidebar-content-${isLeftSidebarExpanded ? "expanded" : "unexpanded"}`}>
                    <VideoOptionsSidebar
                        viewId={viewId}
                        mediaId={mediaId}
                        isExpanded={isLeftSidebarExpanded}
                        setIsExpanded={setIsLeftSidebarExpanded}
                        videoRef={videoRef}
                    />
                </div>

                <div className="review-sidebar-unexpanded-text">
                    <span>F</span>
                    <span>i</span>
                    <span>l</span>
                    <span>t</span>
                    <span>e</span>
                    <span>r</span>
                    <span>s</span>
                </div>

                <div
                    className="expand-button"
                    style={{ justifyContent: "flex-end" }}
                    onClick={() => (isLeftSidebarExpanded ? toggleLeftSidebar() : null)}
                >
                    <span>
                        <FontAwesomeIcon icon={isLeftSidebarExpanded ? faAngleDoubleLeft : faAngleDoubleRight} />
                    </span>
                </div>
            </section>

            <section className="review-player position-relative d-flex flex-column">
                <VideoPlayer
                    key={viewId}
                    videoRef={videoRef}
                    videoType="video/mp4"
                    viewId={viewId}
                    mediaId={mediaId}
                    isLoaded={areFragmentGroupsLoaded && hasLoadedActiveMedia}
                    entitiesInView={entitiesInView}
                    fragmentsMap={fragmentsMap}
                    timestampParam={timestampParam}
                />
            </section>

            <section className="review-controls">
                <VideoControls
                    viewId={viewId}
                    mediaId={mediaId}
                    videoRef={videoRef}
                    entities={view?.filteredEntities}
                    entitiesInView={entitiesInView}
                />
            </section>

            <section
                className={`review-panel review-sidebar-right ${
                    isRightSidebarExpanded ? "review-sidebar-right-expanded" : "review-sidebar-right-unexpanded"
                }`}
                onClick={() => isRightSidebarExpanded || toggleRightSidebar()}
            >
                <div className={`review-sidebar-content-${isRightSidebarExpanded ? "expanded" : "unexpanded"}`}>
                    <VideoToolsSidebar
                        viewId={viewId}
                        isMediaLoaded={hasLoadedActiveMedia}
                        isExpanded={isRightSidebarExpanded}
                        setIsExpanded={setIsRightSidebarExpanded}
                        videoRef={videoRef}
                    />
                </div>

                <div className="review-sidebar-unexpanded-text">
                    <span>T</span>
                    <span>o</span>
                    <span>o</span>
                    <span>l</span>
                    <span>s</span>
                </div>

                <div className="expand-button" onClick={() => (isRightSidebarExpanded ? toggleRightSidebar() : null)}>
                    <span>
                        <FontAwesomeIcon icon={isRightSidebarExpanded ? faAngleDoubleRight : faAngleDoubleLeft} />
                    </span>
                </div>
            </section>

            <section className="review-panel review-timeline">
                <Timeline
                    key={viewId}
                    viewId={viewId}
                    media={activeMediaSource}
                    isLoaded={areFragmentGroupsLoaded && hasLoadedActiveMedia}
                    videoRef={videoRef}
                    entities={view?.filteredEntities}
                    fragmentsMap={fragmentsMap}
                    isShowingDebugUi={userConfig.showDebugUi}
                />
            </section>
        </main>
    );
}

export default Review;
