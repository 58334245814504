import dayjs from "dayjs";
import { Table } from "react-bootstrap";
import ConditionalSpinner from "../../../components/ConditionalSpinner";
import { Organisation } from "../../../models/Organisation";
import { DateFormats } from "../../../utilities/dates";

export interface OrganisationDetailsProps {
    organisation: Organisation;
    isLoading: boolean;
}

function OrganisationDetails(props: OrganisationDetailsProps) {
    return (
        <section className="organisation-details">
            <ConditionalSpinner isLoading={props.isLoading}>
                <Table className="details-table ms-2">
                    <tbody className="px-3">
                        <tr>
                            <th>ID</th>
                            <td className="p-2">{props.organisation?.organisationId}</td>
                        </tr>
                        <tr>
                            <th>Country</th>
                            <td className="p-2">{props.organisation?.country}</td>
                        </tr>
                        <tr>
                            <th>Created At</th>
                            <td className="p-2">
                                {dayjs(props.organisation?.createdAt).format(DateFormats.dayMonthYear)}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </ConditionalSpinner>
        </section>
    );
}

export default OrganisationDetails;
