import HorizontalTabs from "../../components/HorizontalTabs";
import { Organisation as OrganisationType } from "../../models/Organisation";
import { useGetOrganisationQuery } from "../../store/api/kinesense";
import MetadataEditor from "./components/metadataEditor/MetadataEditor";
import OrganisationDetails from "./components/OrganisationDetails";
import Users from "./components/users/Users";
import "./Organisation.scss";

interface OrganisationDetailsProps {
    orgData?: OrganisationType;
}

export default function Organisation(props: OrganisationDetailsProps) {
    const isForCurrentUsersOrganisation = props.orgData === undefined;

    const { data: organisation, isLoading } = isForCurrentUsersOrganisation
        ? useGetOrganisationQuery()
        : { data: props.orgData, isLoading: false };

    const tabsMap = {
        About: <OrganisationDetails organisation={organisation} isLoading={isLoading} />,
        Users: <Users organisation={organisation} isForCurrentUsersOrganisation={isForCurrentUsersOrganisation} />,
    };

    if (isForCurrentUsersOrganisation) {
        tabsMap["Metadata"] = <MetadataEditor />;
    }

    return (
        <main className="container organisation">
            <header className="gap-2 mb-2 d-flex align-items-end">
                <h4 className="mb-0">Organisation</h4>
                <span className="text-muted">&gt;</span>
                <h5 className="mb-0 h4">{organisation?.name}</h5>
            </header>

            <HorizontalTabs tabsMap={tabsMap} />
        </main>
    );
}
