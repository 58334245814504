export const VideoClipResolutionPreserveOptions = ["height", "width", "none"] as const;
export type VideoClipResolutionPreserve = (typeof VideoClipResolutionPreserveOptions)[number];

export interface VideoClipRequest {
    startAt: number;
    endAt: number;
    targetResolution?: {
        width: number;
        height: number;
        preserve: VideoClipResolutionPreserve;
    };
}

export default interface VideoClip {
    name: string;
    fileId: string;
    mediaId: string;
    organisationId: string;
    createdAt: number;
    startAt: number;
    endAt: number;
}
