// Relevant desktop LE files located in VISP/src/VideoFileNameSorter

export function getFilenameDatePatterns(): Record<string, string> {
    const Yrx = String.raw`(?<year>20\d{2})`;
    const Yrx2Dig = String.raw`(?<year2d>\d{2})`;
    const YrxOld = String.raw`(?<year>\d{4})`;
    const Mrx = String.raw`(?<month>0[1-9]|1[0-2])`;
    const Drx = String.raw`(?<day>0[1-9]|[1-2][0-9]|3[0-1])`;
    const Hrx = String.raw`(?<hour>[0-1][0-9]|2[0-3])`;
    const Minrx = String.raw`(?<minute>[0-5][0-9])`;
    const Srx = String.raw`(?<second>[0-5][0-9])`;
    const miliSrx = String.raw`(?<msecond>[0-9][0-9][0-9])`;
    const numberRx = String.raw`(?<number>\d+)`;

    const ampm = String.raw`(?<ampm>AM|PM)`;

    const endYrx = String.raw`(?<endyear>20\d{2})`;
    const endYrxOld = String.raw`(?<endyear>\d{4})`;
    const endMrx = String.raw`(?<endmonth>0[1-9]|1[0-2])`;
    const endDrx = String.raw`(?<endday>0[1-9]|[1-2][0-9]|3[0-1])`;
    const endHrx = String.raw`(?<endhour>[0-1][0-9]|2[0-3])`;
    const endMinrx = String.raw`(?<endminute>[0-5][0-9])`;
    const endSrx = String.raw`(?<endsecond>[0-5][0-9])`;

    const shortMonrx = String.raw`(?<month>[a-zA-Z]{3})`;
    const genericSymbol = String.raw`[-_. ]`;

    return {
        // NOTE: Ordering matters, first matched pattern will be used
        "SWANN 264 Type1": `ch[0-9]+-${Yrx}${Mrx}${Drx}-${Hrx}${Minrx}${Srx}-.+264`,
        "SWANN 264 Type1 with end": `ch[0-9]+-${Yrx2Dig}${Mrx}${Drx}-${Hrx}${Minrx}${Srx}-${endHrx}${endMinrx}${endSrx}.+264`,
        "SWANN 264 Type2": `${Yrx2Dig}${Mrx}${Drx}${Hrx}${Minrx}${Srx}_[0-9]+.+264`,
        "IMM4 Pattern": `_${Drx}${Mrx}${Yrx2Dig}${Hrx}${Minrx}_`,
        "SDR2 Avi Pattern": `${Yrx}${Mrx}${Drx}_${Hrx}${Minrx}${Srx}`,
        "60d Pattern": `${Yrx}${Mrx}${Drx}_${Hrx}${Minrx}${Srx}`,
        "264 Pattern": `${Yrx}${Mrx}${Drx}-${Hrx}${Minrx}${Srx}`,
        "AVF Pattern": `${Yrx}_${Mrx}_${Drx}_${Hrx}_${Minrx}_${Srx}`,
        "AVI SDB Pattern": `${Mrx}${Drx}${Yrx}-${Hrx}${Minrx}${Srx}`,
        "EPT Pattern": `\\[${Yrx}-${Mrx}-${Drx}\\]\\[${Hrx}-${Minrx}-${Srx}\\]`,
        "EXP Pattern": `_${Yrx}${Mrx}${Drx}_${Hrx}${Minrx}`,
        "Mp4 Pattern 1": `_${Drx}_${Mrx}_${Yrx}_${Hrx}_${Minrx}_${Srx}`,
        "Dav Duration": `${Yrx}${Mrx}${Drx}${Hrx}${Minrx}${Srx}_${endYrx}${endMrx}${endDrx}${endHrx}${endMinrx}${endSrx}`,
        "Dav Pattern 1": `_${Yrx}${Mrx}${Drx}${Hrx}${Minrx}${Srx}_`,
        "Dav Pattern 2": `_${Yrx}${Mrx}${Drx}${Hrx}${Minrx}${Srx}_`,
        "Dav Pattern 3": `_${Drx}${Mrx}${Yrx}${Hrx}${Minrx}${Srx}`,
        "h264 GMP Pattern": `-${Yrx2Dig}${Mrx}${Drx}-${Hrx}${Minrx}${Srx}-`,
        "Demo Pattern": `_${Hrx}_${Minrx}_${Srx}_${Drx}_${Mrx}_${Yrx}`,
        "Kinesense Pattern 1": `${Yrx}_${Mrx}_${Drx}-${Hrx}_${Minrx}_${Srx}`,
        "Kinesense Pattern 2": `${Yrx}-${Mrx}-${Drx}_${Hrx}-${Minrx}-${Srx}`,
        "Kinesense Pattern 3": `${Yrx2Dig}${Mrx}${Drx}_${Hrx}${Minrx}${Srx}_${miliSrx}`,
        "NVF Pattern": `${Drx}${shortMonrx}${Yrx}_${Hrx}${Minrx}${Srx}_`,
        "MonthString Pattern": `${Drx}${shortMonrx}${Yrx}${genericSymbol}${Hrx}${genericSymbol}${Minrx}${genericSymbol}${Srx}${genericSymbol}`,
        "G64 Pattern": `_${Yrx}-${Mrx}-${Drx}_${Hrx}h${Minrx}min${Srx}s${miliSrx}ms`,
        "Belgian Pattern with number": `-${Yrx}-${Mrx}-${Drx}_${Hrx}h${Minrx}min${Srx}s${miliSrx}ms_${numberRx}`,
        "Belgian Pattern": `-${Yrx}-${Mrx}-${Drx}_${Hrx}h${Minrx}min${Srx}s${miliSrx}ms`,
        "CaptureTool Pattern": `${Yrx}-${Mrx}-${Drx}~${Hrx}-${Minrx}-${Srx}`,
        "Garda Pattern": `_${Yrx}-${Mrx}-${Drx}_${Hrx}${Minrx}${Srx}_`,
        "Emsou Pattern": `${Drx}_R_${Mrx}${Yrx}${Hrx}${Minrx}${Srx}${ampm}`,
        "Colonel Bourg": `${Yrx}${Mrx}${Drx} ${Hrx}h${Minrx}m${Srx}s${miliSrx}UTC ${endYrx}${endMrx}${endDrx} ${endHrx}h${endMinrx}m${endSrx}s`,
        "Mp4 Pattern 2": `${Yrx}${Mrx}${Drx}${Hrx}${Minrx}${Srx}`,
        "West York Pattern": `${Yrx}-${Mrx}-${Drx} ${Hrx} ${Minrx} ${Srx}`,
        "G64x Pattern 1": `${Yrx}-${Mrx}-${Drx}_${Hrx}_${Minrx}_${Srx}_${miliSrx}`,
        "G64 Pattern 2": `${Yrx}-${Mrx}-${Drx}_${Hrx}h${Minrx}min${Srx}s${miliSrx}`,
        Cleveland: `_${Mrx}${Drx}${Yrx2Dig}${Hrx}${Minrx}${Srx}`,
        NorthWales: `_${Yrx2Dig}${Mrx}${Drx}${Hrx}${Minrx}${Srx}`,
        "SANNCE DVR": `${Drx}-${Mrx}-${YrxOld}__${Hrx}-${Minrx}-${Srx}`,
        "XBA Files": `${YrxOld}\\s*month\\s*${Mrx}\\s*day\\s*${Drx}\\s*${Hrx}_${Minrx}_${Srx}`,
        "ALPR DVR": `${YrxOld}-${Mrx}-${Drx}_${Hrx}${Minrx}`,
        "T DVR": `${YrxOld}-${Mrx}-${Drx}T${Hrx}_${Minrx}_${Srx}`,
        "Another DVR - Type 1": `${Drx}${Mrx}${YrxOld}_${Hrx}${Minrx}${Srx}`,
        "Another DVR - Type 2": `${YrxOld}${Mrx}${Drx}${Hrx}${Minrx}`,
        "SCU5EO DVR": `${YrxOld}-${Drx}-${Mrx}_${Hrx}${Minrx}`,
        "Mov DVR": `${YrxOld}_${Mrx}_${Drx}T${Hrx}_${Minrx}_${Srx}`,
        "Cap DVR": `${YrxOld}${Mrx}${Drx}s${Hrx}h${Minrx}m${Srx}s`,
        "H264 DVR": `-${YrxOld}-${Mrx}-${Drx}-${Hrx}-${Minrx}-${Srx}_`,
        "CSX DVR": `_${YrxOld}_${Mrx}_${Drx}__${Hrx}_${Minrx}_${Srx}_`,
        "New Duration - Type 1": `_${YrxOld}-${Mrx}-${Drx}_${Hrx}${Minrx}${Srx}_${endYrxOld}-${endMrx}-${endDrx}_${endHrx}${endMinrx}${endSrx}`,
        // Not sure why this ignores the end year, month and day, but I'm sure Mark had a good reason
        "New Duration - Type 2": `_${YrxOld}-${Mrx}-${Drx}_${Hrx}${Minrx}${Srx}_(\\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])_${endHrx}${endMinrx}${endSrx}`,
    };
}
