import { useEffect, useMemo, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { TaskItem } from "./TaskItem";
import "./Tasks.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { useGetAllMediaQuery, useGetTasksQuery, useLazyGetVideoClipsQuery } from "../../store/api/kinesense";
import { ApplicationState } from "../../store";
import { useSelector } from "react-redux";
import useInterval from "../../hooks/useInterval";
import { Task } from "../../models/tasks/Task";
import { isDeepEqual } from "../helpers";
import { LayoutGroup } from "framer-motion";
import { Notifications } from "../Notifications/Notifications";
import VideoClip from "../../models/media/VideoClip";
import * as AnalyticsStore from "../../store/analytics/Analytics";
import { useDispatch } from "react-redux";
import { MediaSource } from "../../models/media/MediaSource";
import useAsyncCallback from "../../hooks/useAsyncCallback";

// Used to only show tasks that were already active or only finished during the current session
const OLD_TASK_IDS = [];

/** Calculate the milliseconds until the next re-fetch based on the number of previous fetches which resulted in no updates */
function getRefetchIntervalMs(countUnsuccessfulUpdates: number): number {
    return Math.min(150000, Math.max(5000, countUnsuccessfulUpdates ** 2 * 2000));
}

/** Split tasks by status.
 *
 * @return [activeTasks: Task[], finishedOrFailedTasks: Task[]]*/
function splitTasks(tasks: Task[]): [Task[], Task[]] {
    return (tasks ?? [])
        .filter((t) => !OLD_TASK_IDS.find((id) => id === t.taskId))
        .reduce(
            (acc, task) => {
                if (task.isFailed || task.isComplete) {
                    acc[1].push(task);
                } else {
                    acc[0].push(task);
                }
                return acc;
            },
            [[], []],
        );
}

export const TaskArea = () => {
    const dispatch = useDispatch();

    const { general, analytics } = useSelector((state: ApplicationState) => state);
    const projectId = general?.activeProjectId;
    const views = Object.values(analytics?.views ?? {}).filter((v) => v.projectId === projectId);

    const { data: mediaSources, refetch: requestMediaSources } = useGetAllMediaQuery(
        { projectId },
        { skip: !projectId },
    );

    const {
        data: tasks,
        refetch: refetchTasks,
        isUninitialized,
        isLoading,
    } = useGetTasksQuery({ projectId }, { skip: !projectId });

    // Used for initiating re-fetches of the stored API values when tasks finish
    const [requestClips] = useLazyGetVideoClipsQuery();

    // Keep track of number of task re-fetches which don't result in any new tasks or progress updates on existing tasks
    const [countUnsuccessfulUpdates, setCountUnsuccessfulUpdates] = useState(0);

    const [activeTasks, finishedTasks] = useMemo(() => {
        return splitTasks(tasks);
    }, [tasks, OLD_TASK_IDS.length]);
    const prevFinishedTasksRef = useRef(finishedTasks);

    useEffect(() => {
        if (!isLoading) {
            prevFinishedTasksRef.current = finishedTasks;
            OLD_TASK_IDS.push(...finishedTasks.map((t) => t.taskId));
        }
    }, [isLoading]);

    // Re-fetch tasks on an interval that changes based on the whether previous re-fetches produced any updates
    useInterval(handleRefetchTasks, getRefetchIntervalMs(countUnsuccessfulUpdates));
    // Update handler when `tasks` updates
    useAsyncCallback(onTasksUpdated, [tasks], { skip: isUninitialized });

    async function handleRefetchTasks() {
        if (isUninitialized) {
            return;
        }

        const nextTasks = await refetchTasks().unwrap();
        const hasUpdated = !isDeepEqual(tasks, nextTasks);

        if (!hasUpdated) {
            setCountUnsuccessfulUpdates(countUnsuccessfulUpdates + 1);
        }
        // `onTasksUpdated` should be called separately by the hook defined above, as `nextTasks` becomes `tasks`
    }

    async function onTasksUpdated() {
        setCountUnsuccessfulUpdates(0);

        if (prevFinishedTasksRef.current.length == finishedTasks.length) {
            return;
        }

        // ON COMPLETION / FAILURE OF TASK
        const refetchMediaIds = [];
        const refetchClips: Record<string, string[]> = {};

        for (const task of finishedTasks ?? []) {
            if (prevFinishedTasksRef.current.find((t) => t.taskId == task.taskId) !== undefined) {
                continue;
            }

            const mediaSourceName = getMediaSourceName(task.references.mediaId);

            switch (task.type) {
                case "clip":
                    if (task.isFailed) {
                        console.error("Failed task:", task);
                        Notifications.notify(
                            "Failed to create clip",
                            `Failed to create a video clip for media source "${mediaSourceName}". If this error happens again, please contact customer support.`,
                            "important",
                        );
                    } else {
                        refetchClips[task.references.mediaId] ??= [];
                        refetchClips[task.references.mediaId].push(task.references.outputFileId);

                        Notifications.notify(
                            "Video clip created",
                            `A video clip for media source "${mediaSourceName}" has been created successfully. To download the clip, head over to the videos list on the Review tab.`,
                            "important",
                        );
                    }
                    break;
                case "import":
                    if (task.isFailed) {
                        console.error("Failed task:", task);
                        Notifications.notify(
                            "An import failed",
                            `Failed to import "${getMediaSourceName(
                                task.references.mediaId,
                            )}". If this error happens again, please contact customer support.`,
                        );
                    } else {
                        refetchMediaIds.push(task.references.mediaId);
                        Notifications.notify(
                            "Finished importing",
                            `Import and analysis for media source "${getMediaSourceName(
                                task.references.mediaId,
                            )}" finished successfully. Head over to the Review tab to see the results.`,
                            "important",
                        );
                    }
            }
        }

        await ensureMediaSourcesUpdated(refetchMediaIds);
        await ensureClipsUpdated(refetchClips);

        prevFinishedTasksRef.current = finishedTasks;
    }

    async function ensureMediaSourcesUpdated(mediaIds: string[]) {
        if (!mediaIds?.length) {
            return;
        }

        let mediaSources = await requestMediaSources().unwrap();

        // HACK: Retries in case the media sources haven't yet been updated with the new media source(s)
        let retries = 0;
        const shouldRefetch = (mediaSources: MediaSource[]) =>
            mediaIds.reduce((acc, id) => acc || mediaSources.find((m) => m.mediaId === id) === undefined, false);

        while (shouldRefetch(mediaSources) && retries <= 2) {
            await new Promise((r) => setTimeout(r, 2000));
            mediaSources = await requestMediaSources().unwrap();
            retries += 1;
        }

        // Update view data for updated media sources
        const viewIdsToUpdate = new Set(
            mediaIds
                .map((m) => views.find((v) => v.data.hasAssociatedMedia && v.data.mediaIds.includes(m))?.viewId)
                .filter((v) => v !== undefined),
        );
        for (const viewId of viewIdsToUpdate) {
            dispatch(AnalyticsStore.actionCreators.loadViewData(viewId, true));
        }
    }

    async function ensureClipsUpdated(mediaIdsToFileIds: Record<string, string[]>) {
        await Promise.all(
            Object.entries(mediaIdsToFileIds).map(async ([mediaId, fileIds]) => {
                let clips = await requestClips({ projectId, mediaId }).unwrap();

                // HACK: Retries in case the clips haven't yet been updated with the new clip(s)
                let retries = 0;
                const shouldRefetch = (clips: VideoClip[]) =>
                    fileIds.reduce((acc, f) => acc || clips.find((c) => c.fileId === f) === undefined, false);

                while (shouldRefetch(clips) && retries <= 2) {
                    await new Promise((r) => setTimeout(r, 2000));
                    clips = await requestClips({ projectId, mediaId }).unwrap();
                    retries += 1;
                }
            }),
        );
    }

    function getMediaSourceName(mediaId: string) {
        return mediaSources?.find((m) => m.mediaId === mediaId)?.name ?? "Unknown";
    }

    function renderTasks(tasks: Task[]) {
        return tasks.map((task) => (
            <TaskItem task={task} mediaSourceName={getMediaSourceName(task.references.mediaId)} key={task.taskId} />
        ));
    }

    return (
        <>
            <OverlayTrigger
                placement="top"
                trigger="click"
                rootClose
                onToggle={(nextShow) => {
                    // Reset the count of unsuccessful updates when opening the tasks overlay so that
                    // tasks are re-fetched (help ensure that the user sees up-to-date tasks)
                    if (nextShow) {
                        setCountUnsuccessfulUpdates(0);
                    }
                }}
                overlay={
                    <Popover
                        id="popover-basic"
                        className="mb-1 me-2 task-area"
                        style={{ zIndex: "9999", minWidth: "30rem" }}
                    >
                        <Popover.Header as="h3">Tasks</Popover.Header>
                        <Popover.Body className="p-3 task-scroll">
                            <div className="gap-2 p-0 d-flex flex-column">
                                <LayoutGroup>
                                    {activeTasks.length == 0 && finishedTasks.length == 0 && (
                                        <span className="text-muted">There are no active tasks</span>
                                    )}
                                    {renderTasks(activeTasks)}
                                    {renderTasks(finishedTasks)}
                                </LayoutGroup>
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <button className="task-area-toggle button-with-icon position-relative" title="Tasks">
                    <FontAwesomeIcon icon={faTasks} />

                    {activeTasks.length > 0 && <span className="bg-success task-indicator rounded-circle" />}
                </button>
            </OverlayTrigger>
        </>
    );
};
