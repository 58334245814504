import { Fragment } from "cloud-core/analytics/Fragment";
import { AreaBounds } from "cloud-core/spatial/Spatial";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import useHover from "../../../hooks/useHover";
import useUserConfig from "../../../hooks/useUserConfig";
import { useView } from "../../../hooks/useView";
import { MEDIASOURCE_VIDEO_DEFAULT_FRAMERATE, calculateFramePeriod } from "../../../models/media/MediaSource";
import { Entity } from "../../../models/viz/Entity";
import { isBoundsElementsValid, isInRange } from "../../../utilities/helpers";
import { FragmentsMap } from "./useLoadFragments";
import { GlobalVideoImageSelector } from "../../../utilities/videoImageSelector/VideoImageSelector";
import ReactCrop, { PercentCrop } from "react-image-crop";
import { AreaBoundsEntityFilterParameter } from "../../../models/viz/filters/AreaBoundsEntityFilter";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { MediaSourceWithRunSummariesAndEndsAt } from "../../../store/media/MediaItems";
import dayjs from "dayjs";
import { DateFormats } from "../../../utilities/dates";
// IMPORTANT: Used to provide a fallback to `video.cancelVideoFrameCallback` for browsers which don't support it.
// While not technically necessary, since all modern browsers now support this feature, this is critical functionality,
// so it's a good idea to have a fallback.
import "rvfc-polyfill";

// Used for calculating corner sizes based on overlay/canvas width
const BOUNDING_BOX_RATIO_SIZE = 200;
const BOUNDING_BOX_RATIO_OFFSET = 110;

// A fraction of the video's frame rate (0-1) which represents the maximum distance from the cursor time
// that a fragment can still be drawn on screen. This is to prevent a scenario where a fragment is the
// nearest fragment in its group to the cursor, but is still many frames away, from getting drawn.
const MAX_FRAMERATE_FROM_CURSOR = 0.3;

function drawFilterAreas(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, areas: AreaBounds[]) {
    ctx.fillStyle = "rgba(255, 165, 0, 0.1)";
    ctx.strokeStyle = "rgba(255, 165, 0, 0.5)";

    for (const bounds of areas) {
        const [x1, y1, x2, y2] = convertBounds(canvas, bounds);
        const width = x2 - x1;
        const height = y2 - y1;
        ctx.fillRect(x1, y1, width, height);
        ctx.strokeRect(x1, y1, width, height);
    }
}

function drawBoundingBox(
    ctx: CanvasRenderingContext2D,
    bounds: AreaBounds,
    overlayWidth: number,
    strokeStyle?: { dash: string; corners: string },
) {
    const [x1, y1, x2, y2] = bounds;
    const width = x2 - x1;
    const height = y2 - y1;
    const smallerDimension = Math.min(width, height);

    ctx.lineWidth = 2;
    ctx.lineJoin = "round";

    // DASHED LINE
    ctx.setLineDash([5, 5]);
    ctx.strokeStyle = strokeStyle?.dash ?? "#ffffff";
    ctx.strokeRect(x1, y1, width, height);

    // FANCY CORNERS
    ctx.setLineDash([0]);
    ctx.strokeStyle = strokeStyle?.corners ?? "orange";

    const offset = Math.max(overlayWidth / BOUNDING_BOX_RATIO_OFFSET, 5);
    let size = Math.max(overlayWidth / BOUNDING_BOX_RATIO_SIZE, 1);

    // Ensure gap between corners is at least as big as offset
    if (offset > smallerDimension - size * 2) {
        size = (smallerDimension - offset) / 2;
    }

    ctx.beginPath();

    // top left
    ctx.moveTo(x1 - offset, y1 + size);
    ctx.lineTo(x1 - offset, y1 - offset);
    ctx.lineTo(x1 + size, y1 - offset);

    // top right
    ctx.moveTo(x2 + offset, y1 + size);
    ctx.lineTo(x2 + offset, y1 - offset);
    ctx.lineTo(x2 - size, y1 - offset);

    // bottom left
    ctx.moveTo(x1 - offset, y2 - size);
    ctx.lineTo(x1 - offset, y2 + offset);
    ctx.lineTo(x1 + size, y2 + offset);

    // bottom right
    ctx.moveTo(x2 + offset, y2 - size);
    ctx.lineTo(x2 + offset, y2 + offset);
    ctx.lineTo(x2 - size, y2 + offset);

    ctx.stroke();
}

function drawDebugInfo(ctx: CanvasRenderingContext2D, bounds: AreaBounds, textContent: string, canvasWidth: number) {
    const [x1, y1, x2, y2] = bounds;

    // Set style(s) which affect text measurements first
    ctx.font = "14px sans-serif";

    // Text measurements
    const textMetrics = ctx.measureText(textContent);
    const textWidth = textMetrics.width;
    const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
    const halfTextWidth = textWidth / 2;
    const centrePoint = (x2 + x1) / 2;
    const yMargin = 25;
    const yPadding = 10;
    const xPadding = 10;

    let textX = centrePoint - halfTextWidth;
    let textY = y1 - yMargin;

    // Adjust x if text dimensions will go off canvas
    if (centrePoint + halfTextWidth + xPadding > canvasWidth) {
        textX = canvasWidth - textWidth - xPadding;
    } else if (centrePoint - halfTextWidth - xPadding < 0) {
        textX = xPadding;
    }

    // Adjust y if text dimensions will go off canvas
    if (textY < 0) {
        textY = y2 + yPadding + yMargin;
    }

    // Draw text background
    ctx.fillStyle = "#00000088";
    ctx.fillRect(textX - xPadding, textY - yPadding * 2, textWidth + xPadding * 2, textHeight + yPadding * 2);

    // Draw text
    ctx.fillStyle = "#ffffff";
    ctx.fillText(textContent, textX, textY);
}

/** Calculate Fragment offset's absolute distance from the current cursor offset, in milliseconds */
function calculateFragmentDistance(frag: Fragment, cursorOffsetMilliseconds: number) {
    return Math.abs(frag.frameTimeOffset - cursorOffsetMilliseconds);
}

/** Convert the 0-1 bound values to be 0-(canvas dimensions) so the bounds can be drawn */
function convertBounds(canvas: HTMLCanvasElement, bounds: AreaBounds): AreaBounds {
    if (!isBoundsElementsValid(bounds)) {
        console.error(`The given AreaBounds object is not ordered correctly: ${bounds}`);

        const [x1, y1, x2, y2] = bounds;
        bounds = [Math.min(x1, x2), Math.min(y1, y2), Math.max(x1, x2), Math.max(y1, y2)];
    }

    const [x1, y1, x2, y2] = bounds;
    return [x1 * canvas.width, y1 * canvas.height, x2 * canvas.width, y2 * canvas.height];
}

/** Returns a maximum of 1 fragment per group based on how close it's offset time is to the cursor's offset */
function filterFragments(
    fragmentsMap: FragmentsMap,
    entitiesInView: Entity[],
    cursorOffsetMillisconds: number,
    maxMsFromCursor: number,
    isShowingDebugUi = false,
) {
    let filteredFrags: Fragment[] = [];

    for (const key in fragmentsMap) {
        const frags = fragmentsMap[key];

        if (frags.length == 0) {
            // With debug mode on, if group is empty, use group's bounds to display a fragment
            if (isShowingDebugUi) {
                for (const entity of entitiesInView) {
                    if (entity.sourceObject.id == key) {
                        filteredFrags.push(
                            new Fragment({
                                fragmentGroupId: entity.sourceObject.id,
                                fragmentId: entity.sourceObject.id,
                                bounds: entity.getBoundsOr([0, 0, 0, 0]),
                                frameTimeOffset: 0,
                                metadata: undefined,
                            }),
                        );
                        break;
                    }
                }
            }
            continue;
        }

        // Frags are sorted by `offsetTime` so there's no need to go through every frag,
        // just exit early when the distance to the cursor time starts increasing
        let prevFrag = frags[0];
        let prevDistance = calculateFragmentDistance(frags[0], cursorOffsetMillisconds);
        for (const frag of frags) {
            const currDistance = calculateFragmentDistance(frag, cursorOffsetMillisconds);

            if (currDistance <= prevDistance) {
                prevFrag = frag;
                prevDistance = currDistance;
                continue;
            }

            // Hide fragments which are too far from the cursor time, even if they are the closest
            if (prevDistance <= maxMsFromCursor) {
                filteredFrags.push(prevFrag);
            }

            break;
        }
    }

    // Hide fragments which are fully contained in other fragments
    filteredFrags = filteredFrags.filter((f1) => {
        /* for (const f2 of filteredFrags) { */
        /*     if (f1.fragmentId == f2.fragmentId) { */
        /*         continue; */
        /*     } */
        /**/
        /*     const b1 = f1.bounds; */
        /*     const b2 = f2.bounds; */
        /*     if (b1[0] >= b2[0] && b1[2] <= b2[2] && b1[1] >= b2[1] && b1[3] <= b2[3]) { */
        /*         return false; */
        /*     } */
        /* } */
        return true;
    });

    return filteredFrags;
}

export interface VideoOverlayProps {
    viewId: string;
    media: MediaSourceWithRunSummariesAndEndsAt;
    canvasRef: React.MutableRefObject<HTMLCanvasElement>;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
    videoPlayerOnTimeUpdate: () => void;
    fragmentsMap: MutableRefObject<FragmentsMap>;
    entitiesInView: Entity[];
    maxDimensions: [number, number];
    isPlaying: MutableRefObject<boolean>;
}

function VideoOverlay(props: VideoOverlayProps) {
    const { view } = useView(props.viewId);

    const cursorMilliseconds = view.cursor;
    const cursorOffset = cursorMilliseconds - props.media.startsAt;

    const frameRate = props.media?.files?.initialDisplay?.frameRate ?? MEDIASOURCE_VIDEO_DEFAULT_FRAMERATE;
    const millisecondsBetweenFrames = calculateFramePeriod(props.media?.files?.initialDisplay);
    const maxMsFromCursor = millisecondsBetweenFrames * MAX_FRAMERATE_FROM_CURSOR * frameRate;

    const forceUpdate = useForceUpdate();

    const video = props.videoRef.current;
    const canvas = props.canvasRef.current;

    const [width, height] = props.maxDimensions;

    const { userConfig } = useUserConfig();
    const isShowingDebugUi = userConfig.showDebugUi;
    const { isHovering, mouseLocation } = useHover(props.canvasRef);

    const filterAreas = (view?.filters?.bounds?.params as AreaBoundsEntityFilterParameter)?.areas ?? [];

    const [isSelecting, setIsSelecting] = useState(GlobalVideoImageSelector.isSelecting);
    const [crop, setCrop] = useState<PercentCrop>({
        unit: "%",
        x: 0,
        y: 0,
        width: 50,
        height: 50,
    });

    // NOTE: This reference is necessary so that the `requestVideoFrameCallback` will use up-to-date data.
    // The function's definition can be updated as required, and the old reference that the callback holds will still
    // be valid and point to the new function.
    const updateCanvas: MutableRefObject<() => void> = useRef(null);
    updateCanvas.current = () => {
        props.videoPlayerOnTimeUpdate();

        if (!canvas) {
            return;
        }

        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);

        // Don't draw the rest of the overlay while selection is active
        if (isSelecting) {
            return;
        }

        if (filterAreas.length > 0) {
            drawFilterAreas(canvas, context, filterAreas);
        }

        const filteredFrags = filterFragments(
            props.fragmentsMap.current,
            props.entitiesInView,
            cursorOffset,
            maxMsFromCursor,
            isShowingDebugUi,
        );

        for (const frag of filteredFrags) {
            const convertedBounds = convertBounds(canvas, frag.bounds);

            drawBoundingBox(context, convertedBounds, width);

            if (
                isShowingDebugUi &&
                isHovering &&
                // Mouse is hovering over bounding box if the below 2 conditions are true
                isInRange(mouseLocation[0], [convertedBounds[0], convertedBounds[2]]) &&
                isInRange(mouseLocation[1], [convertedBounds[1], convertedBounds[3]])
            ) {
                // Fragment group bounding box
                const entity = props.entitiesInView.find((e) => e.sourceObject.id === frag.fragmentGroupId);
                if (entity === undefined) {
                    continue;
                }
                //const convertedGroupBounds = convertBounds(canvas, entity.getBoundsOr([0, 0, 1, 1]));
                //drawBoundingBox(context, convertedGroupBounds, width, { dash: "black", corners: "transparent" });

                // Bounding boxes of all other fragments in group
                for (const f of props.fragmentsMap.current[frag.fragmentGroupId] ?? []) {
                    if (f.fragmentId == frag.fragmentId) {
                        continue;
                    }
                    const convertedBounds = convertBounds(canvas, f.bounds);
                    drawBoundingBox(context, convertedBounds, width, { dash: "blue", corners: "transparent" });
                }

                // Fragment debug info
                const time = dayjs(entity.sourceObject.mediaSource.startsAt + frag.frameTimeOffset).format(
                    DateFormats.timeMilliseconds,
                );
                const currDistance = calculateFragmentDistance(frag, cursorOffset);
                const currDistanceSymbol = frag.frameTimeOffset >= cursorOffset ? "+" : "-";

                drawDebugInfo(
                    context,
                    convertedBounds,
                    `${frag.fragmentGroupId} | ${frag.fragmentId} | ${currDistanceSymbol}${currDistance.toFixed(
                        0,
                    )}ms | ${time}`,
                    width,
                );
            }
        }
    };

    // Update canvas based on video events
    useEffect(() => {
        if (!video) {
            return;
        }

        let frameCallbackHandle: number;

        function step() {
            updateCanvas.current();
            // Handle must be updated for each frame
            frameCallbackHandle = video.requestVideoFrameCallback(step);
        }
        step();

        return () => {
            if (frameCallbackHandle !== undefined) {
                video.cancelVideoFrameCallback(frameCallbackHandle);
            }
        };
    }, [video?.src]);

    // Update canvas based on mouse hovering (used for showing debug information)
    useEffect(() => {
        if (!isShowingDebugUi) {
            return;
        }

        updateCanvas.current();
    }, [mouseLocation]);

    // HACK: Perform an additional canvas update when the video is paused, after all fragments for groups that should
    // currently be in view have been loaded. This ensures that all fragments that should be shown for a given
    // frame eventually will be (including for the first frame of a video, before it has been played).
    useEffect(() => {
        if (
            !props.isPlaying.current &&
            props.entitiesInView?.length &&
            props.entitiesInView.filter((e) => props.fragmentsMap.current[e.sourceObject.id] === undefined).length === 0
        ) {
            updateCanvas.current();
        }
    }, [props.isPlaying.current, props.entitiesInView?.length, Object.keys(props.fragmentsMap.current)?.length]);

    const canvasElement = <canvas ref={props.canvasRef} />;

    useEffect(() => {
        GlobalVideoImageSelector.register(setIsSelecting, setCrop);
    }, []);

    useEffect(() => {
        if (isSelecting) {
            GlobalVideoImageSelector.renderComponent();
        } else {
            updateCanvas.current();
        }

        forceUpdate();
    }, [isSelecting]);

    if (!isSelecting) {
        return canvasElement;
    }
    return (
        <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
                setCrop(percentCrop);
                GlobalVideoImageSelector.updatePosition();
            }}
            onComplete={(_, percentCrop) => {
                GlobalVideoImageSelector.setCrop(percentCrop);
            }}
        >
            {canvasElement}
        </ReactCrop>
    );
}

export default VideoOverlay;
