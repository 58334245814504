import dayjs, { Dayjs } from "dayjs";
import { getFilenameDatePatterns } from "./dateFilenamePatterns";

/** Parses a start and end date from the given filename, if a match is found. */
export function parseDatesFromFilename(filename: string): { start: Dayjs | undefined; end: Dayjs | undefined } {
    const result = { start: undefined, end: undefined };
    if (!filename) {
        return result;
    }

    const patterns = getFilenameDatePatterns();

    // Iterate over patterns until a match is found
    for (const [_name, pattern] of Object.entries(patterns)) {
        const regex = new RegExp(pattern, "i");
        // No match, continue to next pattern
        if (!regex.test(filename)) {
            continue;
        }

        const groups = regex.exec(filename).groups;

        // MONTH
        let month = Number.parseInt(groups["month"]) - 1;
        // Couldn't parse month as number, might be given in format `Jan`
        if (Number.isNaN(month) || month < 0) {
            try {
                month = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].indexOf(
                    groups["month"].toLowerCase(),
                );
            } catch {
                continue;
            }
        }

        // HOUR
        let hour = Number.parseInt(groups["hour"]);
        if (groups["ampm"] && groups["ampm"].toLowerCase() == "pm") {
            hour += 12;
        }

        // SECOND
        let sec = !groups["second"] ? 0 : Number.parseInt(groups["second"]);

        // This is to add a little time to stagger odd files that all have the same start time, but then have increment numbers.
        const number = !groups["number"] ? 0 : Number.parseInt(groups["number"]);
        if (!Number.isNaN(number)) {
            sec += number * 10;
        }

        // REST OF START TIME
        const day = Number.parseInt(groups["day"]);
        const min = Number.parseInt(groups["minute"]);
        const year = !groups["year"] ? 2000 + Number.parseInt(groups["year2d"]) : Number.parseInt(groups["year"]);
        const ms = !groups["msecond"] ? 0 : Number.parseInt(groups["msecond"]);

        result.start = dayjs.utc(Date.UTC(year, month, day, hour, min, sec, ms));

        // END TIME
        const endYear = !groups["endyear"] ? year : Number.parseInt(groups["endyear"]);
        const endMonth = !groups["endmonth"] ? month : Number.parseInt(groups["endmonth"]);
        const endDay = !groups["endday"] ? day : Number.parseInt(groups["endday"]);
        const endHour = !groups["endhour"] ? hour : Number.parseInt(groups["endhour"]);
        const endMin = !groups["endminute"] ? min : Number.parseInt(groups["endminute"]);
        const endSec = !groups["endsecond"] ? sec : Number.parseInt(groups["endsecond"]);
        const endMs = ms;

        const end = dayjs.utc(Date.UTC(endYear, endMonth, endDay, endHour, endMin, endSec, endMs));
        // Only set if an actual end time found, not the same value as the start time
        if (!Number.isNaN(end.valueOf()) && end.valueOf() !== result.start.valueOf()) {
            result.end = end;
        }

        return result;
    }

    // RING
    const regex = /(?<hex>\d{19})/;
    if (regex.test(filename)) {
        const decimal = Number.parseInt(regex.exec(filename).groups["hex"]);
        if (!Number.isNaN(decimal) && decimal >= 0) {
            const hex = decimal.toString(16);
            // Take first 8 digits and convert back to decimal
            const seconds_since_unix = Number.parseInt(hex.slice(0, 8), 16);

            if (!Number.isNaN(seconds_since_unix)) {
                result.start = dayjs.utc(seconds_since_unix * 1000);
                return result;
            }
        }
    }

    // FLASHBACK
    let timestamp: string;
    const filename_without_ext = filename.replace(/\.[^/.]+$/, "");
    if (filename_without_ext.length == 8) {
        timestamp = filename_without_ext;
    } else if (filename_without_ext.length == 17 && filename_without_ext[8] == "_") {
        timestamp = filename_without_ext.slice(0, 8);
    }

    if (timestamp) {
        const seconds_since_unix = Number.parseInt(timestamp, 16);
        if (!Number.isNaN(seconds_since_unix)) {
            result.start = dayjs.utc(seconds_since_unix * 1000);
            return result;
        }
    }

    return { start: undefined, end: undefined };
}
