import NiceModal from "@ebay/nice-modal-react";
import ExportClipToOvercastModal from "../../../components/dataOperationModals/exportClipToOvercastModal/ExportClipToOvercastModal";
import { DataOperation, VizContext } from "./DataOperation";

export class ExportClipToOvercastOperation extends DataOperation {
    constructor() {
        super();
        this.operationId = "exportClipToOvercast";
        this.displayName = "Export Clip to Overcast";
        this.icon = {
            type: "image",
            lightUrl: "/images/icons/export-video_56.png",
            darkUrl: "/images/icons/dark-export-video_56.png",
        };
        this.priority = "primary";
        this.context = "selection";
        this.category = "export";
        NiceModal.register(this.operationId, ExportClipToOvercastModal);
    }

    canExecute(context: VizContext) {
        const { view } = context;
        const baseEntity = view?.selectedEntity ?? view?.filteredEntities[0];
        const integrationId = baseEntity?.sourceObject?.mediaSource?.originalSource?.integrationId;

        return (
            integrationId === "overcast" &&
            context.view?.cursor !== undefined &&
            context.view?.secondaryCursor !== undefined
        );
    }

    execute(context: VizContext) {
        NiceModal.show(this.operationId, {
            context,
        });
    }
}
