import { faAngleDoubleLeft, faAngleDoubleRight, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion } from "react-bootstrap";
import Filters from "./Filters";

export interface VideoOptionsSidebarProps {
    viewId: string;
    mediaId: string;
    isExpanded: boolean;
    setIsExpanded: (update: React.SetStateAction<boolean>) => void;
    videoRef: React.MutableRefObject<HTMLVideoElement>;
}

type AccordionKey = "" | "filters";

function VideoOptionsSidebar(props: VideoOptionsSidebarProps) {
    const [activeKey, setActiveKey] = useState<AccordionKey>("");

    function toggleActiveKey(key: AccordionKey) {
        activeKey == key ? setActiveKey("") : setActiveKey(key);
    }

    return (
        <div className="video-sidebar video-sidebar-options">
            <Accordion flush activeKey={activeKey}>
                <Accordion.Item eventKey="filters">
                    <Accordion.Header title="Filters" as="section" onClick={() => toggleActiveKey("filters")}>
                        <div className="video-sidebar-icon">
                            <FontAwesomeIcon icon={faFilter} />
                        </div>
                        <span className="align-self-start">Filters</span>
                    </Accordion.Header>

                    <Accordion.Body className="py-1">
                        <Filters viewId={props.viewId} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}

export default VideoOptionsSidebar;
