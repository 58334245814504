import dayjs from "dayjs";
import { SetStateAction, useEffect, useState } from "react";
import { Button, ProgressBar, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MediaSource } from "../../../../../models/media/MediaSource";
import { ApplicationState } from "../../../../../store";
import {
    useAddMediaSourceMutation,
    useGetTasksQuery,
    useLinkFileToMediaSourceMutation,
    useRequestAnalysisMutation,
    useRequestIntegrationTransferMutation,
} from "../../../../../store/api/kinesense";
import { Notifications } from "../../../../../utilities/Notifications/Notifications";
import { DateFormats } from "../../../../../utilities/dates";
import { UploadUtil, UploadUtilOptions } from "../../fileUpload/UploadUtil";
import { ImportRequestState } from "../ImportWizard";
import "./ImportPages.scss";
import { Link } from "react-router-dom";
import { buildMediaSourceQueryString } from "../../../../../hooks/useManageViews";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHatWizard, faPlay, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { formatBytes, tryExtractErrorMessage } from "../../../../../utilities/helpers";
import { getTaskStepMessage } from "../../../../../models/tasks/Task";

interface SummaryPageProps {
    request: ImportRequestState;
    getUploader: () => UploadUtil | undefined;
    createUploader: (options: UploadUtilOptions) => UploadUtil;
    hideNavButtons: boolean;
    setHideNavButtons: (_: SetStateAction<boolean>) => void;
    resetImportWizard: () => void;
}

type AnalysisRequestState = "initial" | "uploading" | "transferring" | "requested" | "failed" | "complete";

function SummaryPage(props: SummaryPageProps) {
    const { user, general } = useSelector((state: ApplicationState) => state);
    const projectId = general?.activeProjectId;

    const [requestAnalysis] = useRequestAnalysisMutation();
    const [requestIntegrationTransfer] = useRequestIntegrationTransferMutation();
    const [linkFileToMediaSource] = useLinkFileToMediaSourceMutation();
    const [addMediaSource] = useAddMediaSourceMutation();
    const [uploadState, setUploadState] = useState<AnalysisRequestState>("initial");
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploadedMediaSource, setUploadedMediaSource] = useState<MediaSource>(undefined);
    const isUploadComplete = uploadState === "requested";

    const { data: tasks, refetch: refetchTasks } = useGetTasksQuery({ projectId }, { skip: !projectId });

    const task = (tasks ?? []).find((t) => t.references.mediaId == uploadedMediaSource?.mediaId);
    const taskStep = task?.steps?.[task?.currentStepIndex];

    let uploadStateMessage = "In progress...";
    switch (uploadState) {
        case "requested":
            uploadStateMessage = "Completed";
    }

    const progressBarMessage = isUploadComplete ? getTaskStepMessage(taskStep) : `Upload - ${uploadStateMessage}`;
    const progressBarProgress = isUploadComplete ? (taskStep?.progress ?? 0) * 100 ?? 0 : uploadProgress;
    const progressBarVariant = progressBarProgress == 100 ? "success" : undefined;

    const createNewMediaSource = async (): Promise<MediaSource> => {
        return addMediaSource({
            name: props.request.name.data?.name,
            startsAt: props.request.name.data?.startTime?.valueOf(),
            duration: 0,
            type: "video",
            metadata: props.request.metadata.data,
            projectId,
        })
            .unwrap()
            .then((mediaSource) => {
                return mediaSource;
            })
            .catch((e) => {
                Notifications.notify(
                    "Failed to add media source",
                    `The following error was encountered while adding the media source: "${tryExtractErrorMessage(e)}"`,
                    "warning",
                    "errorAnalysisRequest",
                );
                throw e;
            });
    };

    function handleError(error) {
        console.error(error);

        setUploadState("failed");
        setUploadProgress(0);
        props.setHideNavButtons(false);
    }

    const uploadFile = async (mediaSource: MediaSource) => {
        return new Promise<string>((resolve, reject) => {
            let fileId = "";
            const onError = (e) => {
                Notifications.notify(
                    "Error with uploading file",
                    "Please try again. If this error continues to occur, please contact customer support.",
                    "warning",
                    "errorAnalysisRequest",
                );
                reject(e);
                throw e;
            };
            const uploader = props.createUploader({
                file: props.request.source.data?.file,
                onStart: undefined,
                onUploadInitialized: (response) => {
                    console.log("upload initialized", response);
                    fileId = response.fileId;
                },
                onProgress: (progress) => {
                    console.log("progress", progress);
                    setUploadProgress(progress);
                },
                onComplete: () => {
                    console.log("complete");
                    setUploadProgress(100);
                    setUploadedMediaSource(mediaSource);
                    resolve(fileId);
                },
                onError,
                getUser: () => {
                    return user;
                },
            });

            setUploadState("uploading");

            console.log("Uploading file");
            uploader.start().catch(onError);
        });
    };

    const getAnalysisProfile = (): string | undefined => {
        const profile = props.request.algorithm.data?.standardOptions.analysisProfile;

        if (profile && typeof profile !== "string") {
            return undefined;
        }

        return profile as string;
    };

    const sendAnalysisRequest = async (mediaSource: MediaSource) => {
        const profile = getAnalysisProfile();

        const { standardOptions, analyserOptions } = props.request.algorithm.data;
        const analysisRequest = {
            analyserId: props.request.algorithm.data?.analyserId,
            configuration: { standardOptions, analyserOptions } as AnalyserOptions,
            profile: profile,
            frameRateOverride: undefined,
            analysisBounds: undefined,
        };

        return requestAnalysis({
            mediaId: mediaSource.mediaId,
            analysisRequest: analysisRequest,
        })
            .unwrap()
            .then(async (response) => {
                console.log(response);

                // HACK: re-fetch until newly created task appears in the task list
                let nextTasks = tasks;
                let retries = 0;
                while (
                    retries <= 5 &&
                    nextTasks.find((t) => t.references.mediaId === mediaSource.mediaId) === undefined
                ) {
                    await new Promise((r) => setTimeout(r, 1000));
                    nextTasks = await refetchTasks().unwrap();
                    retries += 1;
                }

                setUploadState("requested");
            })
            .catch((e) => {
                Notifications.notify(
                    "Error with requesting analysis",
                    `The following error was encountered while requesting analysis: "${tryExtractErrorMessage(e)}"`,
                    "warning",
                    "errorAnalysisRequest",
                );
                throw e;
            });
    };

    const handleRequestAnalysisClick = async () => {
        props.setHideNavButtons(true);

        try {
            console.log("Creating new media source");
            const mediaSource = await createNewMediaSource();

            let fileId = "";
            switch (props.request.source.data?.type) {
                case "file":
                    fileId = await uploadFile(mediaSource);
                    break;
                case "integration":
                    console.log("Requesting file transfer");
                    setUploadState("transferring");
                    await requestIntegrationTransfer({
                        integrationId: "overcast",
                        integrationMediaId: props.request.source.data.integrationMediaSource.integrationMediaId,
                        mediaId: mediaSource.mediaId,
                    }).unwrap();
                    break;
            }

            // link file to media source
            console.log(`Linking file '${fileId}' to media source '${mediaSource.mediaId}'`);
            await linkFileToMediaSource({
                projectId: general.activeProjectId,
                mediaId: mediaSource.mediaId,
                fileId: fileId,
            })
                .unwrap()
                .catch((e) => {
                    Notifications.notify(
                        "Error with the uploaded file",
                        `The following error was encountered with the uploaded file: "${tryExtractErrorMessage(e)}"`,
                        "warning",
                    );
                    throw e;
                });

            console.log("Requesting analysis");
            await sendAnalysisRequest(mediaSource);
        } catch (e) {
            handleError(e);
        }
    };

    const renderSource = () => {
        switch (props.request.source.data?.type) {
            case "file":
                return props.request.source.data?.file?.name;
            case "integration":
                return "Overcast: " + props.request.source.data?.integrationMediaSource.integrationMediaId;
            case "library":
                return props.request.source.data?.mediaSource.name;
        }
    };

    function renderUploadState() {
        switch (uploadState) {
            case "failed":
                return (
                    <div className="gap-2 d-flex align-items-center">
                        <Button
                            variant="secondary"
                            disabled={props.hideNavButtons}
                            className="gap-2 d-flex align-items-center"
                            onClick={handleRequestAnalysisClick}
                        >
                            <FontAwesomeIcon icon={faRefresh} />
                            Try again
                        </Button>

                        <Button
                            variant="primary"
                            disabled={props.hideNavButtons}
                            className="gap-2 d-flex align-items-center"
                            onClick={props.resetImportWizard}
                        >
                            <FontAwesomeIcon icon={faHatWizard} />
                            Import a different file
                        </Button>
                    </div>
                );
            case "initial":
                return (
                    <Button disabled={props.hideNavButtons} onClick={handleRequestAnalysisClick}>
                        Analyse Video
                    </Button>
                );
            case "transferring":
                return <Button disabled={true}>Transferring file...</Button>;
            case "uploading":
            case "requested":
                return (
                    <div className="gap-2 flex-column d-flex w-100 align-items-center">
                        <label className="gap-1 d-flex flex-column w-100">
                            {progressBarMessage}
                            <ProgressBar
                                key={isUploadComplete ? "analysing" : "uploading"}
                                style={{ height: "1.5rem" }}
                                variant={progressBarVariant}
                                now={progressBarProgress}
                                label={`${Math.round(progressBarProgress)}%`}
                            />
                        </label>

                        {isUploadComplete ? (
                            <div className="gap-2 mt-4 d-flex align-items-center">
                                <Button
                                    style={{ width: "max-content" }}
                                    className="gap-2 btn btn-secondary d-flex align-items-center"
                                    onClick={props.resetImportWizard}
                                >
                                    <FontAwesomeIcon icon={faHatWizard} />
                                    Start another import
                                </Button>

                                <span className="text-muted">or</span>

                                <Link
                                    style={{ width: "max-content" }}
                                    className="gap-2 btn btn-primary d-flex align-items-center"
                                    to={`/review?${buildMediaSourceQueryString(
                                        general.activeProjectId,
                                        uploadedMediaSource?.mediaId,
                                    )}`}
                                >
                                    <FontAwesomeIcon icon={faPlay} />
                                    Go to the new source in Review
                                </Link>
                            </div>
                        ) : (
                            <p className="m-0 fw-bold">Closing this page during file upload will cancel the import.</p>
                        )}
                    </div>
                );
        }
    }

    return (
        <div>
            <Table>
                <tbody>
                    <tr>
                        <td>Source</td>
                        <td>{renderSource()}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{props.request.name.data?.name}</td>
                    </tr>
                    <tr>
                        <td>Start Time</td>
                        <td>
                            {dayjs(props.request.name.data?.startTime).format(DateFormats.yearMonthDayWithTimeSeconds)}
                        </td>
                    </tr>
                    <tr>
                        <td>Algorithm</td>
                        <td>{props.request.algorithm.data?.displayName}</td>
                    </tr>
                    <tr>
                        <td>File size</td>
                        <td>{formatBytes(props.request.source?.data.file?.size ?? 0)}</td>
                    </tr>
                </tbody>
            </Table>
            {uploadState == "initial" && (
                <div className="mt-4 d-flex justify-content-center">
                    If this information is correct, click "Analyse Video" to begin the analysis. If not, click "Back" to
                    change the information.
                </div>
            )}
            <div className="mt-3 d-flex justify-content-center">{renderUploadState()}</div>
        </div>
    );
}

export default SummaryPage;
