import { VizContainerProps } from "./VizContainer";
import { DataOperation, DataOperationCategory, useVizContext } from "../../../models/viz/operations/DataOperation";
import { DataOperationButton } from "./Shared/DataOperationButton";
import { useMemo } from "react";

export type VizOperationsProps = VizContainerProps;

export function VizOperations(props: VizOperationsProps) {
    const context = useVizContext(props.viewId);
    const view = context.view;

    if (view?.currentDataDescription === undefined) {
        return;
    }

    // todo: figure out why using useMemo causes rendering issues
    // const operations = useMemo(() => {
    const validOperations = view?.currentDataDescription.operations.filter(
        (operation) => operation.context == "global" || operation.context == "entity",
    );
    const operations = new Map<DataOperationCategory, DataOperation[]>();

    validOperations.forEach((op) => {
        if (operations.has(op.category)) {
            operations.get(op.category).push(op);
        } else {
            operations.set(op.category, [op]);
        }
    });

    //     return operationsMap;
    // }, [view?.currentDataDescription.operations]);

    return (
        <div className="viz-operations">
            {Array.from(operations).map(([category, operations], i) => (
                <div className="data-operation-group" key={i}>
                    <span className="data-operation-group-title">{category}</span>

                    {operations.map((operation) => (
                        <DataOperationButton
                            key={operation.operationId}
                            operation={operation}
                            context={context}
                            buttonProps={{ size: "sm" }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
}
