import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonProps } from "react-bootstrap";
import { DataOperation, VizContext } from "../../../../models/viz/operations/DataOperation";
import "./DataOperationButton.scss";
import { useTheme } from "../../../../hooks/useTheme";

export function DataOperationButton(props: {
    operation: DataOperation;
    context: VizContext;
    buttonProps?: ButtonProps;
}) {
    const { operation, context } = props;
    const buttonProps = props.buttonProps ?? {};
    const { isDarkTheme } = useTheme();

    return (
        <span title={operation.displayName}>
            <button
                className="data-operation-button"
                onClick={() => operation.execute(context)}
                disabled={!operation.canExecute(context)}
                //{...buttonProps}
            >
                {operation.icon.type === "fontawesome" ? (
                    <FontAwesomeIcon icon={operation.icon.icon} className="fa-fw" />
                ) : (
                    <img src={isDarkTheme ? operation.icon.darkUrl : operation.icon.lightUrl} />
                )}
            </button>
        </span>
    );
}
